<template>
  <div>
    <div v-if="!loading.results && invoice._id" :key="invoice._id">
      <h4>Invoice Values</h4>
      <div class="row">
        <div class="col-md-6">
          <table class="table table-striped mb-4">
            <tbody>
              <tr v-for="value in invoiceValues.slice(0, Math.ceil(invoiceValues.length / 2))" :key="value.key">
                <td class="font-w600">{{ value.name }}</td>
                <td>
                  {{ value.friendlyValue | round(2) }}
                </td>
                <td class="text-info font-w600">
                  {{ value.calculatedValue | round(2) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-6">
          <table class="table table-striped mb-4">
            <tbody>
              <tr v-for="value in invoiceValues.slice(Math.ceil(invoiceValues.length / 2), invoiceValues.length)" :key="value.key">
                <td class="font-w600">{{ value.name }}</td>
                <td>
                  {{ value.friendlyValue | round(2) }}
                </td>
                <td class="text-info font-w600">
                  {{ value.calculatedValue | round(2) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <h4>Invoice Rates</h4>

        <div v-if="invoiceRates.filter(rate => rate.type === 'unitRate') && invoiceRates.filter(rate => rate.type === 'unitRate').length > 0">
          <h5>Unit Rates</h5>
          <InvoiceResultsRateTable :rates="invoiceRates.filter(rate => rate.type === 'unitRate')" />
        </div>

        <div v-if="invoiceRates.filter(rate => rate.type === 'kva') && invoiceRates.filter(rate => rate.type === 'kva').length > 0">
          <h5>Availability Charges</h5>
          <InvoiceResultsRateTable :rates="invoiceRates.filter(rate => rate.type === 'kva')" />
        </div>

        <div v-if="invoiceRates.filter(rate => rate.type === 'levy') && invoiceRates.filter(rate => rate.type === 'levy').length > 0">
          <h5>Levies</h5>
          <InvoiceResultsRateTable :rates="invoiceRates.filter(rate => rate.type === 'levy')" />
        </div>

        <div v-if="invoiceRates.filter(rate => rate.type === 'daily') && invoiceRates.filter(rate => rate.type === 'daily').length > 0">
          <h5>Daily Charges</h5>
          <InvoiceResultsRateTable :rates="invoiceRates.filter(rate => rate.type === 'daily')" />
        </div>

        <div v-if="invoiceRates.filter(rate => rate.type === 'waterRate') && invoiceRates.filter(rate => rate.type === 'waterRate').length > 0">
          <h5>Water Rates</h5>
          <InvoiceResultsRateTable :rates="invoiceRates.filter(rate => rate.type === 'waterRate')" />
        </div>

        <div v-if="invoiceRates.filter(rate => rate.type === 'wasteRate') && invoiceRates.filter(rate => rate.type === 'wasteRate').length > 0">
          <h5>Waste Rates</h5>
          <InvoiceResultsRateTable :rates="invoiceRates.filter(rate => rate.type === 'wasteRate')" />
        </div>
      </div>
    </div>
    <div v-else-if="loading.results" class="d-flex justify-content-center py-5">
      <div class="spinner-border spinner-lg text-secondary my-5" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import slugify from 'slugify';
import { mapActions, mapGetters } from 'vuex';
import InvoiceResultsRateTable from './InvoiceResultsRateTable';

export default {
  name: 'InvoiceResults',
  components: {
    InvoiceResultsRateTable
  },
  props: {
    invoice: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    canEdit: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      invoiceKeys: 'invoiceTemplate/invoiceKeys'
    }),
    invoiceRates() {
      if (!this.invoice.detailedResults) return [];
      return this.invoice.detailedResults.summary || [];
    },
    invoiceValues() {
      if (!this.invoice.detailedResults) return [];

      return (this.invoice.detailedResults.summary || [])
        .filter(value => value.type === 'value' || value.type === 'tax')
        .map(value => {
          if (value.type === 'tax') {
            value.friendlyValue = value.cost;
          }

          return value;
        });
    }
  },
  async created() {
    if (this.invoiceKeys.length === 0) {
      this.getInvoiceKeys();
    }
  },
  methods: {
    ...mapActions({
      download: 'invoice/download',
      getInvoiceKeys: 'invoiceTemplate/getInvoiceKeys'
    }),
    async onClickDownloadInvoice() {
      try {
        const startDate = moment(this.invoice.values.startDate).format('DD-MM-YYYY');
        const endDate = moment(this.invoice.values.endDate).format('DD-MM-YYYY');
        const fileName = `${startDate}-${endDate}_${slugify(this.invoice.supplier.name.toLowerCase())}_${this.invoice.type}.pdf`;

        const downloadUrls = await this.download({ id: this.invoice.id, name: fileName });

        const link = document.createElement('a');
        link.target = '_blank';
        link.href = downloadUrls.url;
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(link.href);
      } catch (e) {
        this.$toasted.error(e.errorMsg || 'Could not download file', { position: 'bottom-center', duration: 3000 });
        console.log(e);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.is-changed {
  color: #da4c4c !important;
  text-decoration: line-through;
  font-weight: 600;
}
.is-calculated {
  color: #3ca761 !important;
  font-weight: 600;
}
</style>
