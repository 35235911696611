<template>
  <div>
    <div class="block block-rounded">
      <div class="block-content mb-4">
        <h4>Pages Classification</h4>
        <p>Manage rules that determine what a page contains.</p>
      </div>
    </div>

    <div class="d-flex justify-content-between align-items-center mb-4">
      <h5 class="mb-0">Pages</h5>
      <button class="btn btn-primary btn-sm" :class="{ disabled: pageForm.show }" :disabled="pageForm.show" @click="onClickShowNewPage">
        <i class="fa fa-fw fa-plus" /> Add Page
      </button>
    </div>

    <!-- NEW PAGE FORM - START -->
    <div v-if="pageForm.started" class="block block-rounded mb-4">
      <div class="block-header" :class="{ 'border-bottom': pageForm.show }">
        <a class="text-success" data-toggle="collapse" href="#" @click.prevent="onClickShowNewPage"
          ><strong>{{ pageForm.name || 'New Page' }}</strong> - {{ pageForm.rules.length }} rule{{ pageForm.rules.length === 1 ? '' : 's' }}</a
        >
        <span>
          <button class="btn btn-link font-w600" @click="onClickDiscardNewPage">Discard</button>
        </span>
      </div>
      <div class="collapse" :class="{ show: pageForm.show }">
        <div class="block-content block-content-full">
          <FormGroup id="pageName" v-model="pageForm.name" label="Page Name" type="text" :is-alt="true" />
          <FormGroup
            id="pageMatchType"
            v-model="pageForm.matchType"
            type="radio"
            label="Match Type"
            :options="[
              { label: 'All', value: 'all' },
              { label: 'Any', value: 'any' }
            ]"
          />

          <div class="d-flex justify-content-between align-items-center mb-4">
            <label class="mb-0">Rules</label>
            <button class="btn btn-primary btn-sm" @click="onClickNewAddRule"><i class="fa fa-fw fa-plus" /> Add Rule</button>
          </div>
          <div v-if="pageForm.rules.length > 0">
            <div v-for="(rule, ruleIndex) in pageForm.rules" :key="ruleIndex" class="block block-rounded bg-body">
              <div class="block-content block-content-full mb-4">
                <div v-if="rule.isEdit" class="input-group">
                  <FormItem :id="`rule${ruleIndex}-value`" v-model="rule.newValue" placeholder="Enter a regex rule..." type="text" />
                  <div class="input-group-append">
                    <button class="btn btn-primary" @click="() => onClickNewConfirmRule(ruleIndex)"><i class="fa fa-fw fa-check" /></button>
                    <button class="btn btn-danger" @click="() => onClickNewCancelRule(ruleIndex)"><i class="fa fa-fw fa-times" /></button>
                  </div>
                </div>
                <div v-else class="d-flex justify-content-between align-items-center">
                  <strong>{{ rule.value }}</strong>
                  <span>
                    <button class="btn btn-primary btn-sm mr-3" @click="() => onClickNewEditRule(ruleIndex)">
                      <i class="fa fa-fw fa-pencil" />
                    </button>
                    <button class="btn btn-danger btn-sm" @click="() => onClickNewDeleteRule(ruleIndex)">
                      <i class="fa fa-fw fa-trash" />
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="alert alert-warning" role="alert">
            <p class="mb-0">No rules set.</p>
          </div>
        </div>
      </div>
    </div>
    <!-- NEW PAGE FORM - END -->

    <!-- EXISTING PAGES - START -->
    <div v-for="(page, index) in pages" :key="index" class="block block-rounded mb-4">
      <div class="block-header" :class="{ 'border-bottom': page.show }">
        <a :class="{ 'text-danger': page.delete, 'text-strike': page.delete }" href="#" @click.prevent="() => onClickShowExisting(index)"
          ><strong>{{ page.name }}</strong> <span class="badge badge-success ml-2">{{ page.rules.length }}</span></a
        >
        <span>
          <button v-if="!page.delete" class="btn btn-link font-w600 mr-3" @click="() => onClickShowExisting(index)">Manage</button>
          <button v-if="!page.delete" class="btn btn-link font-w600 text-danger" @click="() => onClickDeleteExisting(index)">Delete</button>
          <button v-if="page.delete" class="btn btn-link font-w600" @click="() => onClickDeleteExisting(index)">Undo</button>
        </span>
      </div>
      <div class="collapse" :class="{ show: page.show }">
        <div class="block-content block-content-full">
          <FormGroup id="pageName" v-model="page.name" label="Page Name" type="text" :is-alt="true" />
          <FormGroup
            id="pageMatchType"
            v-model="page.matchType"
            type="radio"
            label="Match Type"
            :options="[
              { label: 'All', value: 'all' },
              { label: 'Any', value: 'any' }
            ]"
          />
          <div class="d-flex justify-content-between align-items-center mb-4">
            <label class="mb-0">Rules</label>
            <button class="btn btn-primary btn-sm" @click="() => onClickExistingAddRule(index)"><i class="fa fa-fw fa-plus" /> Add Rule</button>
          </div>
          <div v-if="page.rules.length > 0">
            <div v-for="(rule, ruleIndex) in page.rules" :key="ruleIndex" class="block block-rounded bg-body">
              <div class="block-content block-content-full mb-4">
                <div v-if="rule.isEdit" class="input-group">
                  <FormItem :id="`rule${ruleIndex}-value`" v-model="rule.newValue" placeholder="Enter a regex rule..." type="text" />
                  <div class="input-group-append">
                    <button class="btn btn-primary" @click="() => onClickExistingConfirmRule(index, ruleIndex)">
                      <i class="fa fa-fw fa-check" />
                    </button>
                    <button class="btn btn-danger" @click="() => onClickExistingCancelRule(index, ruleIndex)">
                      <i class="fa fa-fw fa-times" />
                    </button>
                  </div>
                </div>
                <div v-else class="d-flex justify-content-between align-items-center">
                  <strong>{{ rule.value }}</strong>
                  <span>
                    <button class="btn btn-primary btn-sm mr-3" @click="() => onClickExistingEditRule(index, ruleIndex)">
                      <i class="fa fa-fw fa-pencil" />
                    </button>
                    <button class="btn btn-danger btn-sm" @click="() => onClickDeleteExistingRule(index, ruleIndex)">
                      <i class="fa fa-fw fa-trash" />
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="alert alert-warning" role="alert">
            <p class="mb-0">No rules set.</p>
          </div>
        </div>
      </div>
    </div>

    <button class="btn btn-primary btn-block" :disabled="loading.savePages" @click="onClickSavePages">
      <i class="fa fa-fw fa-save" /> {{ loading.savePages ? 'Saving...' : 'Save Changes' }}
    </button>
  </div>
  <!-- EXISTING PAGES - END -->
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import FormGroup from '@/components/FormGroup';
import FormItem from '@/components/FormItem';

export default {
  name: 'SupplierTemplatePages',
  components: {
    FormGroup,
    FormItem
  },
  data() {
    return {
      pageEditForm: {},
      pageForm: {
        rules: [],
        name: '',
        matchType: 'any',
        show: false,
        started: false
      },
      pages: []
    };
  },
  watch: {
    template() {
      this.pages = [...this.template.pages].map(p => ({ ...p, show: false, delete: false }));
    }
  },
  mounted() {
    this.pages = [...this.template.pages].map(p => ({ ...p, show: false, delete: false }));
  },
  computed: {
    ...mapGetters({
      template: 'invoiceTemplate/template',
      loading: 'invoiceTemplate/loading',
      supplier: 'supplier/supplier'
    })
  },
  methods: {
    ...mapActions({
      savePages: 'invoiceTemplate/savePages'
    }),
    ...mapMutations({
      setSelectedPage: 'invoiceTemplate/SET_SELECTED_PAGE'
    }),
    onClickShowNewPage() {
      this.pageForm.started = true;
      this.pageForm.show = !this.pageForm.show;
    },
    onClickDiscardNewPage() {
      this.pageForm = {
        rules: [],
        name: '',
        matchType: 'any',
        show: false,
        started: false
      };
    },
    onClickShowExisting(index) {
      this.$set(this.pages[index], 'show', !this.pages[index].show);
    },
    onClickDeleteExisting(index) {
      this.$set(this.pages[index], 'delete', !this.pages[index].delete);

      this.pages.forEach(page => {
        page.show = false;
      });
    },
    onClickNewAddRule() {
      this.pageForm.rules.push({
        type: 'regex',
        value: '',
        newValue: '',
        isEdit: true
      });
    },
    onClickNewConfirmRule(index) {
      this.$set(this.pageForm.rules[index], 'value', this.pageForm.rules[index].newValue);
      this.$set(this.pageForm.rules[index], 'newValue', '');
      this.$set(this.pageForm.rules[index], 'isEdit', false);
    },
    onClickNewDeleteRule(index) {
      this.pageForm.rules.splice(index, 1);
    },
    onClickNewEditRule(index) {
      this.$set(this.pageForm.rules[index], 'newValue', this.pageForm.rules[index].value);
      this.$set(this.pageForm.rules[index], 'isEdit', true);
    },
    onClickNewCancelRule(index) {
      if (this.pageForm.rules[index].value === '') {
        this.pageForm.rules.splice(index, 1);
      } else {
        this.$set(this.pageForm.rules[index], 'newValue', '');
        this.$set(this.pageForm.rules[index], 'isEdit', false);
      }
    },

    onClickExistingAddRule(pageIndex) {
      this.pages[pageIndex].rules.push({
        type: 'regex',
        value: '',
        newValue: '',
        isEdit: true
      });
    },
    onClickExistingConfirmRule(pageIndex, ruleIndex) {
      this.$set(this.pages[pageIndex].rules[ruleIndex], 'value', this.pages[pageIndex].rules[ruleIndex].newValue);
      this.$set(this.pages[pageIndex].rules[ruleIndex], 'newValue', '');
      this.$set(this.pages[pageIndex].rules[ruleIndex], 'isEdit', false);
    },
    onClickDeleteExistingRule(pageIndex, ruleIndex) {
      this.pages[pageIndex].rules.splice(ruleIndex, 1);
    },
    onClickExistingEditRule(pageIndex, ruleIndex) {
      this.$set(this.pages[pageIndex].rules[ruleIndex], 'newValue', this.pages[pageIndex].rules[ruleIndex].value);
      this.$set(this.pages[pageIndex].rules[ruleIndex], 'isEdit', true);
    },
    onClickExistingCancelRule(pageIndex, ruleIndex) {
      if (this.pages[pageIndex].rules[ruleIndex].value === '') {
        this.pages[pageIndex].rules.splice(ruleIndex, 1);
      } else {
        this.$set(this.pages[pageIndex].rules[ruleIndex], 'newValue', '');
        this.$set(this.pages[pageIndex].rules[ruleIndex], 'isEdit', false);
      }
    },

    async onClickSavePages() {
      let newPages = [];

      if (this.pageForm.started) {
        newPages.push({
          name: this.pageForm.name || 'New Page',
          matchType: this.pageForm.matchType,
          rules: this.pageForm.rules
        });
      }

      await this.savePages({
        id: this.template._id,
        data: [...newPages, ...this.pages.filter(p => !p.delete)]
      });

      this.pages = [...this.template.pages].map(p => ({ ...p, show: false, delete: false }));

      this.pageForm = {
        rules: [],
        name: '',
        matchType: 'any',
        show: false
      };

      this.$emit('updatePreview');

      this.$toasted.success('Rules saved successfully.');
    }
  }
};
</script>
