<template>
  <div>
    <div class="block block-rounded">
      <div class="block-content mb-4">
        <h4>Bounds Classification</h4>
        <p>Manage rules that determine what a bound contains.</p>
        <div class="custom-control custom-switch mb-4">
          <input
            id="show-lines"
            type="checkbox"
            class="custom-control-input"
            name="show-lines"
            :checked="settings.showLines"
            @input="e => toggleLines(e.target.checked)"
          />
          <label class="custom-control-label" for="show-lines">Show Lines</label>
        </div>
        <div class="custom-control custom-switch mb-4">
          <input
            id="show-bounds"
            type="checkbox"
            class="custom-control-input"
            name="show-bounds"
            :checked="settings.showBounds"
            @input="e => toggleBounds(e.target.checked)"
          />
          <label class="custom-control-label" for="show-bounds">Show Bounds</label>
        </div>
        <div class="custom-control custom-switch mb-4">
          <input
            id="show-cells"
            type="checkbox"
            class="custom-control-input"
            name="show-cells"
            :checked="settings.showCells"
            @input="e => toggleCells(e.target.checked)"
          />
          <label class="custom-control-label" for="show-cells">Show Tables</label>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between align-items-center mb-4">
      <h5 class="mb-0">Bounds</h5>
      <button class="btn btn-primary btn-sm" :class="{ disabled: boundForm.show }" :disabled="boundForm.show" @click="onClickShowNew">
        <i class="fa fa-fw fa-plus" /> Add Bound
      </button>
    </div>

    <!-- NEW BOUND FORM - START -->
    <div v-if="boundForm.started" class="block block-rounded mb-4">
      <div class="block-header" :class="{ 'border-bottom': boundForm.show }">
        <a class="text-success" data-toggle="collapse" href="#" @click.prevent="onClickShowNew"
          ><strong>{{ boundForm.name || 'New Bound' }}</strong></a
        >
        <span>
          <button class="btn btn-link font-w600" @click="onClickDiscardNew">Discard</button>
        </span>
      </div>
      <div class="collapse" :class="{ show: boundForm.show }">
        <div class="block-content block-content-full">
          <FormGroup id="boundName" v-model="boundForm.name" label="Name" type="text" :is-alt="true" />
          <FormGroup
            id="boundPage"
            v-model="boundForm.page"
            label="Page"
            :is-alt="true"
            type="select"
            :options="template.pages.map(p => ({ label: p.name, value: p.name }))"
          />
          <FormGroup
            id="boundsType"
            v-model="boundForm.type"
            type="radio"
            label="Type"
            :options="[
              { label: 'Bound', value: 'bound' },
              { label: 'Table', value: 'table' }
            ]"
            @input="onChangeTypeNew"
          />

          <FormGroup
            id="boundTableType"
            v-model="boundForm.tableType"
            type="select"
            label="Table Type"
            :options="[
              { label: 'Standard', value: 'standard' },
              { label: 'Standard per page', value: 'standard-page' },
              { label: 'Period per row', value: 'periods' }
            ]"
          />

          <FormGroup
            id="boundTableTypeRegex"
            v-model="boundForm.tableTypeRegex"
            label="Cell Split Regex"
            type="text"
            :is-alt="true"
            :error="newBoundPreview.error"
          />

          <!-- Table settings -->
          <div v-if="boundForm.type === 'table'">
            <div class="form-group">
              <label>Table Regex</label>
              <div class="input-group">
                <FormItem id="boundTableRegex" v-model="boundForm.regex" type="text" :is-alt="true" :error="newBoundPreview.error" />
                <div class="input-group-append">
                  <FormItem
                    id="boundRegexSkip"
                    v-model="boundForm.regexSkip"
                    type="select"
                    class="bg-gray"
                    :options="[
                      { label: '1st', value: 0 },
                      { label: '2nd', value: 1 },
                      { label: '3rd', value: 2 },
                      { label: '4th', value: 3 },
                      { label: '5th', value: 4 },
                      { label: '6th', value: 5 },
                      { label: '7th', value: 6 },
                      { label: '8th', value: 7 },
                      { label: '9th', value: 8 },
                      { label: '10th', value: 9 }
                    ]"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <FormGroup
                  id="boundCellDirection"
                  v-model="boundForm.cellDirection"
                  type="select"
                  label="Cell Direction"
                  :options="[
                    { label: 'Row (Up/Down)', value: 'row' },
                    { label: 'Columns (Left/Right)', value: 'column' }
                  ]"
                />
                <FormGroup id="boundCellOffset" v-model="boundForm.cellOffset" type="number" label="Cell Offset" min="-100" max="100" step="1" />
              </div>
              <div class="col-md-6">
                <FormGroup
                  id="boundCellDirection2"
                  v-model="boundForm.cellDirection2"
                  type="select"
                  label="Cell Direction 2"
                  :options="[
                    { label: 'Row (Up/Down)', value: 'row' },
                    { label: 'Columns (Left/Right)', value: 'column' }
                  ]"
                />
                <FormGroup id="boundCellOffset2" v-model="boundForm.cellOffset2" type="number" label="Cell Offset 2" min="-100" max="100" step="1" />
              </div>
            </div>

            <FormGroup
              id="boundTableCellsStopRegex"
              v-model="boundForm.tableCellsStopRegex"
              label="Stop At Regex"
              type="text"
              :is-alt="true"
              :error="newBoundPreview.error"
              description="Use this to narrow down the lines below"
            />

            <FormGroup
              id="boundTableRowRegex"
              v-model="boundForm.tableRowRegex"
              label="Row Regex"
              type="text"
              :is-alt="true"
              :error="newBoundPreview.error"
              description="Capture specific rows"
            />
          </div>
          <!-- Table settings END -->

          <div v-if="boundForm.type === 'bound'">
            <FormGroup
              id="boundsGroup"
              v-model="boundForm.group"
              type="radio"
              label="Group By"
              :options="[
                { label: 'Seperate', value: 'seperate' },
                { label: 'Combine', value: 'combine' }
              ]"
            />
            <div class="form-group">
              <label>Regex</label>
              <div class="input-group">
                <FormItem id="boundRegex" v-model="boundForm.regex" type="text" :is-alt="true" :error="newBoundPreview.error" />
                <div class="input-group-append">
                  <FormItem
                    id="boundRegexType"
                    v-model="boundForm.regexType"
                    type="select"
                    class="bg-gray"
                    :options="[
                      { label: 'Capture', value: 'capture' },
                      { label: 'Replace', value: 'replace' }
                    ]"
                  />
                </div>
              </div>
            </div>
          </div>

          <label>Lines</label>

          <div v-if="newBoundPreview">
            <table class="table table-borderless table-striped">
              <tbody>
                <tr v-for="(line, lineIndex) in newBoundPreview.lines" :key="lineIndex">
                  <td>{{ line.text }}</td>
                  <td class="text-right align-middle">
                    <span class="badge badge-primary">{{ parseFloat(line.confidence).toFixed(2) }}%</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else class="alert alert-warning mb-0" role="alert">
            <p class="mb-0">No lines within bounds.</p>
          </div>
        </div>
      </div>
    </div>
    <!-- NEW BOUND FORM - END -->

    <!-- EXISTING BOUNDS - START -->
    <div v-for="(bound, index) in bounds" :key="index" class="block block-rounded mb-4">
      <div class="block-header" :class="{ 'border-bottom': bound.show }">
        <a :class="{ 'text-danger': bound.delete, 'text-strike': bound.delete }" href="#" @click.prevent="() => onClickShowExisting(index)"
          ><strong>{{ bound.name }}</strong> <span class="badge badge-success ml-2">{{ bound.page }} Page</span
          ><span class="badge badge-info text-capitalize ml-2">{{ bound.type }}</span></a
        >
        <span>
          <button v-if="!bound.delete" class="btn btn-link font-w600 mr-3" @click="() => onClickShowExisting(index)">Manage</button>
          <button v-if="!bound.delete" class="btn btn-link font-w600 text-danger" @click="() => onClickDeleteExisting(index)">Delete</button>
          <button v-if="bound.delete" class="btn btn-link font-w600" @click="() => onClickDeleteExisting(index)">Undo</button>
        </span>
      </div>
      <div class="collapse" :class="{ show: bound.show }">
        <div class="block-content block-content-full">
          <FormGroup :id="`boundName-${index}`" v-model="bound.name" label="Bound Name" type="text" :is-alt="true" />
          <FormGroup
            :id="`boundPage-${index}`"
            v-model="bound.page"
            label="Page"
            :is-alt="true"
            type="select"
            :options="template.pages.map(p => ({ label: p.name, value: p.name }))"
          />
          <FormGroup
            :id="`boundType-${index}`"
            v-model="bound.type"
            type="radio"
            label="Type"
            :options="[
              { label: 'Bound', value: 'bound' },
              { label: 'Table', value: 'table' }
            ]"
            @input="type => onChangeTypeExisting(type, bound, index)"
          />
          <FormGroup
            id="boundTableType"
            v-model="bound.tableType"
            type="select"
            label="Table Type"
            :options="[
              { label: 'Standard', value: 'standard' },
              { label: 'Standard per page', value: 'standard-page' },
              { label: 'Period per row', value: 'periods' }
            ]"
          />

          <FormGroup id="boundTableTypeRegex" v-model="bound.tableTypeRegex" label="Cell Split Regex" type="text" :is-alt="true" />

          <!-- Table settings -->
          <div v-if="bound.type === 'table'">
            <div class="form-group">
              <label>Table Regex</label>
              <div class="input-group">
                <FormItem id="boundTableRegex" v-model="bound.regex" type="text" :is-alt="true" :error="boundPreviews[bound.name].error" />
                <div class="input-group-append">
                  <FormItem
                    id="boundRegexSkip"
                    v-model="bound.regexSkip"
                    type="select"
                    class="bg-gray"
                    :options="[
                      { label: '1st', value: 0 },
                      { label: '2nd', value: 1 },
                      { label: '3rd', value: 2 },
                      { label: '4th', value: 3 },
                      { label: '5th', value: 4 },
                      { label: '6th', value: 5 },
                      { label: '7th', value: 6 },
                      { label: '8th', value: 7 },
                      { label: '9th', value: 8 },
                      { label: '10th', value: 9 }
                    ]"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <FormGroup
                  id="boundTableDirection"
                  v-model="bound.cellDirection"
                  type="select"
                  label="Cell Direction"
                  :options="[
                    { label: 'Row (Up/Down)', value: 'row' },
                    { label: 'Columns (Left/Right)', value: 'column' }
                  ]"
                />
                <FormGroup id="boundCellOffset" v-model="bound.cellOffset" type="number" label="Cell Offset" min="-100" max="100" step="1" />
              </div>
              <div class="col-md-6">
                <FormGroup
                  id="boundTableDirection2"
                  v-model="bound.cellDirection2"
                  type="select"
                  label="Cell Direction 2"
                  :options="[
                    { label: 'Row (Up/Down)', value: 'row' },
                    { label: 'Columns (Left/Right)', value: 'column' }
                  ]"
                />
                <FormGroup id="boundCellOffset2" v-model="bound.cellOffset2" type="number" label="Cell Offset 2" min="-100" max="100" step="1" />
              </div>
            </div>

            <FormGroup
              id="boundTableCellsStopRegex"
              v-model="bound.tableCellsStopRegex"
              label="Stop At Regex"
              type="text"
              :is-alt="true"
              description="Use this to narrow down the lines below"
            />

            <FormGroup
              id="boundTableRowRegex"
              v-model="bound.tableRowRegex"
              label="Row Regex"
              type="text"
              :is-alt="true"
              description="Capture specific rows"
            />
          </div>
          <!-- Table settings END -->

          <!-- Bound settings -->
          <div v-if="bound.type === 'bound'">
            <FormGroup
              :id="`boundsGroup-${index}`"
              v-model="bound.group"
              type="radio"
              label="Group By"
              :options="[
                { label: 'Seperate', value: 'seperate' },
                { label: 'Combine', value: 'combine' }
              ]"
            />

            <div class="form-group">
              <label>Regex</label>
              <div class="input-group">
                <FormItem :id="`boundRegex-${index}`" v-model="bound.regex" type="text" :is-alt="true" :error="boundPreviews[bound.name].error" />
                <div class="input-group-append">
                  <FormItem
                    :id="`boundRegexType-${index}`"
                    v-model="bound.regexType"
                    type="select"
                    class="bg-gray"
                    :options="[
                      { label: 'Capture', value: 'capture' },
                      { label: 'Replace', value: 'replace' }
                    ]"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- Bound settings END -->

          <label>Lines</label>

          <div v-if="boundPreviews[bound.name]">
            <table class="table table-borderless table-striped">
              <tbody>
                <tr v-for="(line, lineIndex) in boundPreviews[bound.name].lines" :key="lineIndex">
                  <td>{{ line.text }}</td>
                  <td class="text-right align-middle">
                    <span class="badge badge-primary">{{ parseFloat(line.confidence).toFixed(2) }}%</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else class="alert alert-warning mb-0" role="alert">
            <p class="mb-0">No lines within bounds.</p>
          </div>
        </div>
      </div>
    </div>

    <button class="btn btn-primary btn-block" :disabled="loading.saveBounds" @click="onClickSaveBounds">
      <i class="fa fa-fw fa-save" /> {{ loading.saveBounds ? 'Saving...' : 'Save Changes' }}
    </button>
    <!-- EXISTING BOUNDS - END -->
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import { getBoundPreview } from '../../../lib/templateHelpers';
import FormGroup from '@/components/FormGroup';
import FormItem from '@/components/FormItem';

export default {
  name: 'SupplierTemplateBounds',
  components: {
    FormGroup,
    FormItem
  },
  props: {
    userBound: {
      type: Object,
      required: true
    },
    currentLines: {
      type: Array,
      required: true
    },
    currentCells: {
      type: Array,
      required: true
    },
    currentPage: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      boundEditForm: {},
      boundForm: {
        active: false,
        start: { x: 0, y: 0 },
        end: { x: 0, y: 0 },
        name: '',
        type: 'bound',
        group: 'seperate',
        regex: '',
        regexSkip: 0,
        regexType: 'capture',
        tableType: 'standard',
        tableTypeRegex: '',
        tableCellsStopRegex: '',
        tableRowRegex: '',
        cellDirection: 'row',
        cellOffset: 1,
        cellDirection2: 'row',
        cellOffset2: 0,
        page: '',
        show: false,
        started: false,
        invalidRegex: false,
        useUserBound: false
      },
      bounds: []
    };
  },
  mounted() {
    this.bounds = [...this.template.bounds].map(p => ({ ...p, show: false, delete: false }));

    this.boundForm = {
      ...this.boundForm,
      page: this.currentPage ? this.currentPage.name : '',
      left: this.userBound.leftPct,
      top: this.userBound.topPct,
      width: this.userBound.widthPct,
      height: this.userBound.heightPct
    };
  },
  computed: {
    ...mapGetters({
      template: 'invoiceTemplate/template',
      loading: 'invoiceTemplate/loading',
      settings: 'invoiceTemplate/settings',
      supplier: 'supplier/supplier'
    }),
    newBoundPreview() {
      if (
        this.boundForm.type !== 'table' &&
        this.userBound.left > 5 &&
        this.userBound.top > 5 &&
        this.userBound.width > 5 &&
        this.userBound.height > 5
      ) {
        const { leftPct: left, topPct: top, widthPct: width, heightPct: height } = this.userBound;
        return getBoundPreview(this.currentLines, { ...this.boundForm, left, top, width, height });
      } else {
        return getBoundPreview(this.boundForm.type === 'table' ? this.currentCells : this.currentLines, this.boundForm);
      }
    },
    boundPreviews() {
      const boundMap = {};

      this.bounds.forEach(bound => {
        if (
          bound.type !== 'table' &&
          bound.useUserBound &&
          this.userBound.left > 5 &&
          this.userBound.top > 5 &&
          this.userBound.width > 5 &&
          this.userBound.height > 5
        ) {
          const { leftPct: left, topPct: top, widthPct: width, heightPct: height } = this.userBound;
          boundMap[bound.name] = getBoundPreview(this.currentLines, { ...bound, left, top, width, height });
        } else {
          boundMap[bound.name] = getBoundPreview(bound.type === 'table' ? this.currentCells : this.currentLines, bound);
        }
      });

      return boundMap;
    }
  },
  watch: {
    userBound(userBound) {
      if (userBound.left > 5 && userBound.top > 5 && userBound.width > 5 && userBound.height > 5) {
        this.boundForm = {
          ...this.boundForm,
          page: this.currentPage ? this.currentPage.name : '',
          left: userBound.leftPct,
          top: userBound.topPct,
          width: userBound.widthPct,
          height: userBound.heightPct
        };

        this.bounds
          .filter(bound => bound.show)
          .forEach(bound => {
            bound.left = userBound.leftPct;
            bound.top = userBound.topPct;
            bound.width = userBound.widthPct;
            bound.height = userBound.heightPct;
            bound.useUserBound = true;
          });
      } else {
        this.bounds
          .filter(bound => bound.show)
          .forEach(bound => {
            const originalBound = this.template.bounds.find(b => b.name === bound.name);

            bound.left = originalBound.left;
            bound.top = originalBound.top;
            bound.width = originalBound.width;
            bound.height = originalBound.height;
          });
      }
    },
    template() {
      this.bounds = [...this.template.bounds].map(p => ({ ...p, show: false, delete: false }));

      this.boundForm = {
        ...this.boundForm,
        page: this.currentPage ? this.currentPage.name : '',
        left: this.userBound.leftPct,
        top: this.userBound.topPct,
        width: this.userBound.widthPct,
        height: this.userBound.heightPct
      };
    }
  },
  methods: {
    ...mapActions({
      saveBounds: 'invoiceTemplate/saveBounds'
    }),
    ...mapMutations({
      toggleLines: 'invoiceTemplate/TOGGLE_LINES',
      toggleBounds: 'invoiceTemplate/TOGGLE_BOUNDS',
      toggleCells: 'invoiceTemplate/TOGGLE_CELLS',
      allowDrawing: 'invoiceTemplate/ALLOW_DRAWING'
    }),
    resetBoundForm(override = {}) {
      this.allowDrawing(true);
      this.boundForm = {
        active: false,
        start: { x: 0, y: 0 },
        end: { x: 0, y: 0 },
        name: '',
        page: this.currentPage ? this.currentPage.name : '',
        type: 'bound',
        tableType: 'standard',
        tableTypeRegex: '',
        tableCellsStopRegex: '',
        tableRowRegex: '',
        cellDirection: 'row',
        cellOffset: '1',
        cellDirection2: 'row',
        cellOffset2: '0',
        group: 'seperate',
        regex: '',
        regexSkip: 0,
        regexType: 'capture',
        show: false,
        left: this.userBound.left,
        top: this.userBound.top,
        width: this.userBound.width,
        height: this.userBound.height,
        invalidRegex: false,
        useUserBound: false,
        ...override
      };
    },
    onClickShowNew() {
      this.allowDrawing(true);
      this.boundForm.started = true;
      this.boundForm.show = !this.boundForm.show;
    },
    onClickDiscardNew() {
      this.resetBoundForm();
    },
    onChangeTypeNew(type) {
      this.boundForm.regex = '';

      this.allowDrawing(type === 'bound');
      this.toggleLines(type === 'bound');
      this.toggleBounds(type === 'bound');
      this.toggleCells(type === 'table');
    },
    onChangeTypeExisting(type, bound, index) {
      const originalBound = this.template.bounds.find(b => b.name === bound.name);

      if ((!originalBound.type && type === 'bound') || originalBound.type === type) {
        this.$set(this.bounds[index], 'regex', originalBound.regex);
      } else {
        this.$set(this.bounds[index], 'regex', '');
      }

      this.allowDrawing(type === 'bound');
      this.toggleLines(type === 'bound');
      this.toggleBounds(type === 'bound');
      this.toggleCells(type === 'table');
    },
    onClickShowExisting(index) {
      this.$set(this.bounds[index], 'show', !this.bounds[index].show);
    },
    onClickDeleteExisting(index) {
      this.$set(this.bounds[index], 'delete', !this.bounds[index].delete);

      this.bounds.forEach(bound => {
        bound.show = false;
      });
    },

    async onClickSaveBounds() {
      let newBounds = [];

      if (this.boundForm.started) {
        newBounds.push({
          name: this.boundForm.name,
          page: this.boundForm.page,
          type: this.boundForm.type,
          group: this.boundForm.group,
          regex: this.boundForm.regex,
          regexType: this.boundForm.regexType,
          regexSkip: this.boundForm.regexSkip,
          tableType: this.boundForm.tableType,
          tableTypeRegex: this.boundForm.tableTypeRegex,
          tableCellsStopRegex: this.boundForm.tableCellsStopRegex,
          tableRowRegex: this.boundForm.tableRowRegex,
          cellDirection: this.boundForm.cellDirection,
          cellOffset: this.boundForm.cellOffset,
          cellDirection2: this.boundForm.cellDirection2,
          cellOffset2: this.boundForm.cellOffset2,
          left: this.boundForm.left,
          top: this.boundForm.top,
          width: this.boundForm.width,
          height: this.boundForm.height
        });
      }

      const updatedBounds = this.bounds
        .filter(bound => !bound.delete)
        .map(bound => ({
          name: bound.name,
          page: bound.page,
          type: bound.type,
          group: bound.group,
          regex: bound.regex,
          regexType: bound.regexType,
          regexSkip: bound.regexSkip,
          tableType: bound.tableType,
          tableTypeRegex: bound.tableTypeRegex,
          tableCellsStopRegex: bound.tableCellsStopRegex,
          tableRowRegex: bound.tableRowRegex,
          cellDirection: bound.cellDirection,
          cellOffset: bound.cellOffset,
          cellDirection2: bound.cellDirection2,
          cellOffset2: bound.cellOffset2,
          left: bound.left,
          top: bound.top,
          width: bound.width,
          height: bound.height
        }));
      await this.saveBounds({
        id: this.template._id,
        data: [...newBounds, ...updatedBounds]
      });

      this.bounds = [...this.template.bounds].map(p => ({ ...p, show: false, delete: false }));

      this.resetBoundForm();

      this.$emit('updatePreview');

      this.$toasted.success('Rules saved successfully.');
    }
  }
};
</script>
