var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "block block-rounded"
  }, [_c('div', {
    staticClass: "block-content mb-4"
  }, [_c('h4', [_vm._v("Version Classification")]), _c('p', [_vm._v(" When any of the rules below match on any page, the invoice will be classed as "), _c('strong', [_vm._v(_vm._s(_vm.versionName))]), _vm._v(". ")])])]), _c('FormGroup', {
    attrs: {
      "id": "version",
      "label": "Version Name",
      "type": "text"
    },
    model: {
      value: _vm.versionName,
      callback: function callback($$v) {
        _vm.versionName = $$v;
      },
      expression: "versionName"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "version",
      "label": "Match Type",
      "type": "radio",
      "options": [{
        label: 'Any',
        value: 'any'
      }, {
        label: 'All',
        value: 'all'
      }]
    },
    model: {
      value: _vm.versionMatchType,
      callback: function callback($$v) {
        _vm.versionMatchType = $$v;
      },
      expression: "versionMatchType"
    }
  }), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center mb-4"
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v("Rules")]), _c('button', {
    staticClass: "btn btn-primary btn-sm",
    on: {
      "click": function click() {
        return _vm.onClickAddRule(_vm.index);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-plus"
  }), _vm._v(" Add Rule")])]), _vm.versionRules.length > 0 ? _c('div', _vm._l(_vm.versionRules, function (rule, index) {
    return _c('div', {
      key: index,
      staticClass: "block block-rounded mb-4"
    }, [_c('div', {
      staticClass: "block-content block-content-full"
    }, [rule.isEdit ? _c('div', {
      staticClass: "input-group"
    }, [_c('FormItem', {
      attrs: {
        "id": "rule".concat(index, "-value"),
        "placeholder": "Enter a regex rule...",
        "is-alt": true,
        "type": "text"
      },
      model: {
        value: rule.newValue,
        callback: function callback($$v) {
          _vm.$set(rule, "newValue", $$v);
        },
        expression: "rule.newValue"
      }
    }), _c('div', {
      staticClass: "input-group-append"
    }, [_c('button', {
      staticClass: "btn btn-primary",
      on: {
        "click": function click() {
          return _vm.onClickConfirmRule(index);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-check"
    })]), _c('button', {
      staticClass: "btn btn-danger",
      on: {
        "click": function click() {
          return _vm.onClickCancelRule(index);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-times"
    })])])], 1) : _c('div', {
      staticClass: "d-flex justify-content-between align-items-center"
    }, [_c('strong', {
      class: {
        'text-danger': rule.isDelete,
        'text-strike': rule.isDelete
      }
    }, [_vm._v(_vm._s(rule.value))]), _c('span', [!rule.isDelete ? _c('button', {
      staticClass: "btn btn-link font-w600 mr-3",
      on: {
        "click": function click() {
          return _vm.onClickEditRule(index);
        }
      }
    }, [_vm._v("Manage")]) : _vm._e(), !rule.isDelete ? _c('button', {
      staticClass: "btn btn-link font-w600 text-danger",
      on: {
        "click": function click() {
          return _vm.onClickDeleteRule(index);
        }
      }
    }, [_vm._v("Delete")]) : _vm._e(), rule.isDelete ? _c('button', {
      staticClass: "btn btn-link font-w600",
      on: {
        "click": function click() {
          return _vm.onClickDeleteRule(index);
        }
      }
    }, [_vm._v("Undo")]) : _vm._e()])])])]);
  }), 0) : _c('div', {
    staticClass: "alert alert-warning",
    attrs: {
      "role": "alert"
    }
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v("No rules set.")])]), _c('button', {
    staticClass: "btn btn-primary btn-block mb-4",
    attrs: {
      "disabled": _vm.loading.saveVersionRules
    },
    on: {
      "click": _vm.onClickSave
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-save"
  }), _vm._v(" " + _vm._s(_vm.loading.saveVersionRules ? 'Saving...' : 'Save Changes') + " ")]), _c('button', {
    staticClass: "btn btn-danger btn-block",
    attrs: {
      "disabled": _vm.loading.deleteTemplate
    },
    on: {
      "click": _vm.onClickDelete
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-exclamation-triangle"
  }), _vm._v(" " + _vm._s(_vm.loading.deleteTemplate ? 'Deleting...' : 'Delete Template') + " ")]), _c('ConfirmModal', {
    attrs: {
      "open": !!_vm.isDeleteModal,
      "title": "Confirm delete",
      "text": "You are about to delete ".concat(_vm.template.version, " of this template. All supplier rules, page rules, bounds and\n  values will be deleted for this version.")
    },
    on: {
      "submit": _vm.onConfirmDelete,
      "close": function close() {
        _vm.isDeleteModal = false;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }