var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "block block-rounded"
  }, [_c('div', {
    staticClass: "block-content mb-4"
  }, [_c('h4', [_vm._v("Bounds Classification")]), _c('p', [_vm._v("Manage rules that determine what a bound contains.")]), _c('div', {
    staticClass: "custom-control custom-switch mb-4"
  }, [_c('input', {
    staticClass: "custom-control-input",
    attrs: {
      "id": "show-lines",
      "type": "checkbox",
      "name": "show-lines"
    },
    domProps: {
      "checked": _vm.settings.showLines
    },
    on: {
      "input": function input(e) {
        return _vm.toggleLines(e.target.checked);
      }
    }
  }), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "show-lines"
    }
  }, [_vm._v("Show Lines")])]), _c('div', {
    staticClass: "custom-control custom-switch mb-4"
  }, [_c('input', {
    staticClass: "custom-control-input",
    attrs: {
      "id": "show-bounds",
      "type": "checkbox",
      "name": "show-bounds"
    },
    domProps: {
      "checked": _vm.settings.showBounds
    },
    on: {
      "input": function input(e) {
        return _vm.toggleBounds(e.target.checked);
      }
    }
  }), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "show-bounds"
    }
  }, [_vm._v("Show Bounds")])]), _c('div', {
    staticClass: "custom-control custom-switch mb-4"
  }, [_c('input', {
    staticClass: "custom-control-input",
    attrs: {
      "id": "show-cells",
      "type": "checkbox",
      "name": "show-cells"
    },
    domProps: {
      "checked": _vm.settings.showCells
    },
    on: {
      "input": function input(e) {
        return _vm.toggleCells(e.target.checked);
      }
    }
  }), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "show-cells"
    }
  }, [_vm._v("Show Tables")])])])]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center mb-4"
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v("Bounds")]), _c('button', {
    staticClass: "btn btn-primary btn-sm",
    class: {
      disabled: _vm.boundForm.show
    },
    attrs: {
      "disabled": _vm.boundForm.show
    },
    on: {
      "click": _vm.onClickShowNew
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-plus"
  }), _vm._v(" Add Bound ")])]), _vm.boundForm.started ? _c('div', {
    staticClass: "block block-rounded mb-4"
  }, [_c('div', {
    staticClass: "block-header",
    class: {
      'border-bottom': _vm.boundForm.show
    }
  }, [_c('a', {
    staticClass: "text-success",
    attrs: {
      "data-toggle": "collapse",
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onClickShowNew($event);
      }
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.boundForm.name || 'New Bound'))])]), _c('span', [_c('button', {
    staticClass: "btn btn-link font-w600",
    on: {
      "click": _vm.onClickDiscardNew
    }
  }, [_vm._v("Discard")])])]), _c('div', {
    staticClass: "collapse",
    class: {
      show: _vm.boundForm.show
    }
  }, [_c('div', {
    staticClass: "block-content block-content-full"
  }, [_c('FormGroup', {
    attrs: {
      "id": "boundName",
      "label": "Name",
      "type": "text",
      "is-alt": true
    },
    model: {
      value: _vm.boundForm.name,
      callback: function callback($$v) {
        _vm.$set(_vm.boundForm, "name", $$v);
      },
      expression: "boundForm.name"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "boundPage",
      "label": "Page",
      "is-alt": true,
      "type": "select",
      "options": _vm.template.pages.map(function (p) {
        return {
          label: p.name,
          value: p.name
        };
      })
    },
    model: {
      value: _vm.boundForm.page,
      callback: function callback($$v) {
        _vm.$set(_vm.boundForm, "page", $$v);
      },
      expression: "boundForm.page"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "boundsType",
      "type": "radio",
      "label": "Type",
      "options": [{
        label: 'Bound',
        value: 'bound'
      }, {
        label: 'Table',
        value: 'table'
      }]
    },
    on: {
      "input": _vm.onChangeTypeNew
    },
    model: {
      value: _vm.boundForm.type,
      callback: function callback($$v) {
        _vm.$set(_vm.boundForm, "type", $$v);
      },
      expression: "boundForm.type"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "boundTableType",
      "type": "select",
      "label": "Table Type",
      "options": [{
        label: 'Standard',
        value: 'standard'
      }, {
        label: 'Standard per page',
        value: 'standard-page'
      }, {
        label: 'Period per row',
        value: 'periods'
      }]
    },
    model: {
      value: _vm.boundForm.tableType,
      callback: function callback($$v) {
        _vm.$set(_vm.boundForm, "tableType", $$v);
      },
      expression: "boundForm.tableType"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "boundTableTypeRegex",
      "label": "Cell Split Regex",
      "type": "text",
      "is-alt": true,
      "error": _vm.newBoundPreview.error
    },
    model: {
      value: _vm.boundForm.tableTypeRegex,
      callback: function callback($$v) {
        _vm.$set(_vm.boundForm, "tableTypeRegex", $$v);
      },
      expression: "boundForm.tableTypeRegex"
    }
  }), _vm.boundForm.type === 'table' ? _c('div', [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Table Regex")]), _c('div', {
    staticClass: "input-group"
  }, [_c('FormItem', {
    attrs: {
      "id": "boundTableRegex",
      "type": "text",
      "is-alt": true,
      "error": _vm.newBoundPreview.error
    },
    model: {
      value: _vm.boundForm.regex,
      callback: function callback($$v) {
        _vm.$set(_vm.boundForm, "regex", $$v);
      },
      expression: "boundForm.regex"
    }
  }), _c('div', {
    staticClass: "input-group-append"
  }, [_c('FormItem', {
    staticClass: "bg-gray",
    attrs: {
      "id": "boundRegexSkip",
      "type": "select",
      "options": [{
        label: '1st',
        value: 0
      }, {
        label: '2nd',
        value: 1
      }, {
        label: '3rd',
        value: 2
      }, {
        label: '4th',
        value: 3
      }, {
        label: '5th',
        value: 4
      }, {
        label: '6th',
        value: 5
      }, {
        label: '7th',
        value: 6
      }, {
        label: '8th',
        value: 7
      }, {
        label: '9th',
        value: 8
      }, {
        label: '10th',
        value: 9
      }]
    },
    model: {
      value: _vm.boundForm.regexSkip,
      callback: function callback($$v) {
        _vm.$set(_vm.boundForm, "regexSkip", $$v);
      },
      expression: "boundForm.regexSkip"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('FormGroup', {
    attrs: {
      "id": "boundCellDirection",
      "type": "select",
      "label": "Cell Direction",
      "options": [{
        label: 'Row (Up/Down)',
        value: 'row'
      }, {
        label: 'Columns (Left/Right)',
        value: 'column'
      }]
    },
    model: {
      value: _vm.boundForm.cellDirection,
      callback: function callback($$v) {
        _vm.$set(_vm.boundForm, "cellDirection", $$v);
      },
      expression: "boundForm.cellDirection"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "boundCellOffset",
      "type": "number",
      "label": "Cell Offset",
      "min": "-100",
      "max": "100",
      "step": "1"
    },
    model: {
      value: _vm.boundForm.cellOffset,
      callback: function callback($$v) {
        _vm.$set(_vm.boundForm, "cellOffset", $$v);
      },
      expression: "boundForm.cellOffset"
    }
  })], 1), _c('div', {
    staticClass: "col-md-6"
  }, [_c('FormGroup', {
    attrs: {
      "id": "boundCellDirection2",
      "type": "select",
      "label": "Cell Direction 2",
      "options": [{
        label: 'Row (Up/Down)',
        value: 'row'
      }, {
        label: 'Columns (Left/Right)',
        value: 'column'
      }]
    },
    model: {
      value: _vm.boundForm.cellDirection2,
      callback: function callback($$v) {
        _vm.$set(_vm.boundForm, "cellDirection2", $$v);
      },
      expression: "boundForm.cellDirection2"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "boundCellOffset2",
      "type": "number",
      "label": "Cell Offset 2",
      "min": "-100",
      "max": "100",
      "step": "1"
    },
    model: {
      value: _vm.boundForm.cellOffset2,
      callback: function callback($$v) {
        _vm.$set(_vm.boundForm, "cellOffset2", $$v);
      },
      expression: "boundForm.cellOffset2"
    }
  })], 1)]), _c('FormGroup', {
    attrs: {
      "id": "boundTableCellsStopRegex",
      "label": "Stop At Regex",
      "type": "text",
      "is-alt": true,
      "error": _vm.newBoundPreview.error,
      "description": "Use this to narrow down the lines below"
    },
    model: {
      value: _vm.boundForm.tableCellsStopRegex,
      callback: function callback($$v) {
        _vm.$set(_vm.boundForm, "tableCellsStopRegex", $$v);
      },
      expression: "boundForm.tableCellsStopRegex"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "boundTableRowRegex",
      "label": "Row Regex",
      "type": "text",
      "is-alt": true,
      "error": _vm.newBoundPreview.error,
      "description": "Capture specific rows"
    },
    model: {
      value: _vm.boundForm.tableRowRegex,
      callback: function callback($$v) {
        _vm.$set(_vm.boundForm, "tableRowRegex", $$v);
      },
      expression: "boundForm.tableRowRegex"
    }
  })], 1) : _vm._e(), _vm.boundForm.type === 'bound' ? _c('div', [_c('FormGroup', {
    attrs: {
      "id": "boundsGroup",
      "type": "radio",
      "label": "Group By",
      "options": [{
        label: 'Seperate',
        value: 'seperate'
      }, {
        label: 'Combine',
        value: 'combine'
      }]
    },
    model: {
      value: _vm.boundForm.group,
      callback: function callback($$v) {
        _vm.$set(_vm.boundForm, "group", $$v);
      },
      expression: "boundForm.group"
    }
  }), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Regex")]), _c('div', {
    staticClass: "input-group"
  }, [_c('FormItem', {
    attrs: {
      "id": "boundRegex",
      "type": "text",
      "is-alt": true,
      "error": _vm.newBoundPreview.error
    },
    model: {
      value: _vm.boundForm.regex,
      callback: function callback($$v) {
        _vm.$set(_vm.boundForm, "regex", $$v);
      },
      expression: "boundForm.regex"
    }
  }), _c('div', {
    staticClass: "input-group-append"
  }, [_c('FormItem', {
    staticClass: "bg-gray",
    attrs: {
      "id": "boundRegexType",
      "type": "select",
      "options": [{
        label: 'Capture',
        value: 'capture'
      }, {
        label: 'Replace',
        value: 'replace'
      }]
    },
    model: {
      value: _vm.boundForm.regexType,
      callback: function callback($$v) {
        _vm.$set(_vm.boundForm, "regexType", $$v);
      },
      expression: "boundForm.regexType"
    }
  })], 1)], 1)])], 1) : _vm._e(), _c('label', [_vm._v("Lines")]), _vm.newBoundPreview ? _c('div', [_c('table', {
    staticClass: "table table-borderless table-striped"
  }, [_c('tbody', _vm._l(_vm.newBoundPreview.lines, function (line, lineIndex) {
    return _c('tr', {
      key: lineIndex
    }, [_c('td', [_vm._v(_vm._s(line.text))]), _c('td', {
      staticClass: "text-right align-middle"
    }, [_c('span', {
      staticClass: "badge badge-primary"
    }, [_vm._v(_vm._s(parseFloat(line.confidence).toFixed(2)) + "%")])])]);
  }), 0)])]) : _c('div', {
    staticClass: "alert alert-warning mb-0",
    attrs: {
      "role": "alert"
    }
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v("No lines within bounds.")])])], 1)])]) : _vm._e(), _vm._l(_vm.bounds, function (bound, index) {
    return _c('div', {
      key: index,
      staticClass: "block block-rounded mb-4"
    }, [_c('div', {
      staticClass: "block-header",
      class: {
        'border-bottom': bound.show
      }
    }, [_c('a', {
      class: {
        'text-danger': bound.delete,
        'text-strike': bound.delete
      },
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return function () {
            return _vm.onClickShowExisting(index);
          }($event);
        }
      }
    }, [_c('strong', [_vm._v(_vm._s(bound.name))]), _vm._v(" "), _c('span', {
      staticClass: "badge badge-success ml-2"
    }, [_vm._v(_vm._s(bound.page) + " Page")]), _c('span', {
      staticClass: "badge badge-info text-capitalize ml-2"
    }, [_vm._v(_vm._s(bound.type))])]), _c('span', [!bound.delete ? _c('button', {
      staticClass: "btn btn-link font-w600 mr-3",
      on: {
        "click": function click() {
          return _vm.onClickShowExisting(index);
        }
      }
    }, [_vm._v("Manage")]) : _vm._e(), !bound.delete ? _c('button', {
      staticClass: "btn btn-link font-w600 text-danger",
      on: {
        "click": function click() {
          return _vm.onClickDeleteExisting(index);
        }
      }
    }, [_vm._v("Delete")]) : _vm._e(), bound.delete ? _c('button', {
      staticClass: "btn btn-link font-w600",
      on: {
        "click": function click() {
          return _vm.onClickDeleteExisting(index);
        }
      }
    }, [_vm._v("Undo")]) : _vm._e()])]), _c('div', {
      staticClass: "collapse",
      class: {
        show: bound.show
      }
    }, [_c('div', {
      staticClass: "block-content block-content-full"
    }, [_c('FormGroup', {
      attrs: {
        "id": "boundName-".concat(index),
        "label": "Bound Name",
        "type": "text",
        "is-alt": true
      },
      model: {
        value: bound.name,
        callback: function callback($$v) {
          _vm.$set(bound, "name", $$v);
        },
        expression: "bound.name"
      }
    }), _c('FormGroup', {
      attrs: {
        "id": "boundPage-".concat(index),
        "label": "Page",
        "is-alt": true,
        "type": "select",
        "options": _vm.template.pages.map(function (p) {
          return {
            label: p.name,
            value: p.name
          };
        })
      },
      model: {
        value: bound.page,
        callback: function callback($$v) {
          _vm.$set(bound, "page", $$v);
        },
        expression: "bound.page"
      }
    }), _c('FormGroup', {
      attrs: {
        "id": "boundType-".concat(index),
        "type": "radio",
        "label": "Type",
        "options": [{
          label: 'Bound',
          value: 'bound'
        }, {
          label: 'Table',
          value: 'table'
        }]
      },
      on: {
        "input": function input(type) {
          return _vm.onChangeTypeExisting(type, bound, index);
        }
      },
      model: {
        value: bound.type,
        callback: function callback($$v) {
          _vm.$set(bound, "type", $$v);
        },
        expression: "bound.type"
      }
    }), _c('FormGroup', {
      attrs: {
        "id": "boundTableType",
        "type": "select",
        "label": "Table Type",
        "options": [{
          label: 'Standard',
          value: 'standard'
        }, {
          label: 'Standard per page',
          value: 'standard-page'
        }, {
          label: 'Period per row',
          value: 'periods'
        }]
      },
      model: {
        value: bound.tableType,
        callback: function callback($$v) {
          _vm.$set(bound, "tableType", $$v);
        },
        expression: "bound.tableType"
      }
    }), _c('FormGroup', {
      attrs: {
        "id": "boundTableTypeRegex",
        "label": "Cell Split Regex",
        "type": "text",
        "is-alt": true
      },
      model: {
        value: bound.tableTypeRegex,
        callback: function callback($$v) {
          _vm.$set(bound, "tableTypeRegex", $$v);
        },
        expression: "bound.tableTypeRegex"
      }
    }), bound.type === 'table' ? _c('div', [_c('div', {
      staticClass: "form-group"
    }, [_c('label', [_vm._v("Table Regex")]), _c('div', {
      staticClass: "input-group"
    }, [_c('FormItem', {
      attrs: {
        "id": "boundTableRegex",
        "type": "text",
        "is-alt": true,
        "error": _vm.boundPreviews[bound.name].error
      },
      model: {
        value: bound.regex,
        callback: function callback($$v) {
          _vm.$set(bound, "regex", $$v);
        },
        expression: "bound.regex"
      }
    }), _c('div', {
      staticClass: "input-group-append"
    }, [_c('FormItem', {
      staticClass: "bg-gray",
      attrs: {
        "id": "boundRegexSkip",
        "type": "select",
        "options": [{
          label: '1st',
          value: 0
        }, {
          label: '2nd',
          value: 1
        }, {
          label: '3rd',
          value: 2
        }, {
          label: '4th',
          value: 3
        }, {
          label: '5th',
          value: 4
        }, {
          label: '6th',
          value: 5
        }, {
          label: '7th',
          value: 6
        }, {
          label: '8th',
          value: 7
        }, {
          label: '9th',
          value: 8
        }, {
          label: '10th',
          value: 9
        }]
      },
      model: {
        value: bound.regexSkip,
        callback: function callback($$v) {
          _vm.$set(bound, "regexSkip", $$v);
        },
        expression: "bound.regexSkip"
      }
    })], 1)], 1)]), _c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col-md-6"
    }, [_c('FormGroup', {
      attrs: {
        "id": "boundTableDirection",
        "type": "select",
        "label": "Cell Direction",
        "options": [{
          label: 'Row (Up/Down)',
          value: 'row'
        }, {
          label: 'Columns (Left/Right)',
          value: 'column'
        }]
      },
      model: {
        value: bound.cellDirection,
        callback: function callback($$v) {
          _vm.$set(bound, "cellDirection", $$v);
        },
        expression: "bound.cellDirection"
      }
    }), _c('FormGroup', {
      attrs: {
        "id": "boundCellOffset",
        "type": "number",
        "label": "Cell Offset",
        "min": "-100",
        "max": "100",
        "step": "1"
      },
      model: {
        value: bound.cellOffset,
        callback: function callback($$v) {
          _vm.$set(bound, "cellOffset", $$v);
        },
        expression: "bound.cellOffset"
      }
    })], 1), _c('div', {
      staticClass: "col-md-6"
    }, [_c('FormGroup', {
      attrs: {
        "id": "boundTableDirection2",
        "type": "select",
        "label": "Cell Direction 2",
        "options": [{
          label: 'Row (Up/Down)',
          value: 'row'
        }, {
          label: 'Columns (Left/Right)',
          value: 'column'
        }]
      },
      model: {
        value: bound.cellDirection2,
        callback: function callback($$v) {
          _vm.$set(bound, "cellDirection2", $$v);
        },
        expression: "bound.cellDirection2"
      }
    }), _c('FormGroup', {
      attrs: {
        "id": "boundCellOffset2",
        "type": "number",
        "label": "Cell Offset 2",
        "min": "-100",
        "max": "100",
        "step": "1"
      },
      model: {
        value: bound.cellOffset2,
        callback: function callback($$v) {
          _vm.$set(bound, "cellOffset2", $$v);
        },
        expression: "bound.cellOffset2"
      }
    })], 1)]), _c('FormGroup', {
      attrs: {
        "id": "boundTableCellsStopRegex",
        "label": "Stop At Regex",
        "type": "text",
        "is-alt": true,
        "description": "Use this to narrow down the lines below"
      },
      model: {
        value: bound.tableCellsStopRegex,
        callback: function callback($$v) {
          _vm.$set(bound, "tableCellsStopRegex", $$v);
        },
        expression: "bound.tableCellsStopRegex"
      }
    }), _c('FormGroup', {
      attrs: {
        "id": "boundTableRowRegex",
        "label": "Row Regex",
        "type": "text",
        "is-alt": true,
        "description": "Capture specific rows"
      },
      model: {
        value: bound.tableRowRegex,
        callback: function callback($$v) {
          _vm.$set(bound, "tableRowRegex", $$v);
        },
        expression: "bound.tableRowRegex"
      }
    })], 1) : _vm._e(), bound.type === 'bound' ? _c('div', [_c('FormGroup', {
      attrs: {
        "id": "boundsGroup-".concat(index),
        "type": "radio",
        "label": "Group By",
        "options": [{
          label: 'Seperate',
          value: 'seperate'
        }, {
          label: 'Combine',
          value: 'combine'
        }]
      },
      model: {
        value: bound.group,
        callback: function callback($$v) {
          _vm.$set(bound, "group", $$v);
        },
        expression: "bound.group"
      }
    }), _c('div', {
      staticClass: "form-group"
    }, [_c('label', [_vm._v("Regex")]), _c('div', {
      staticClass: "input-group"
    }, [_c('FormItem', {
      attrs: {
        "id": "boundRegex-".concat(index),
        "type": "text",
        "is-alt": true,
        "error": _vm.boundPreviews[bound.name].error
      },
      model: {
        value: bound.regex,
        callback: function callback($$v) {
          _vm.$set(bound, "regex", $$v);
        },
        expression: "bound.regex"
      }
    }), _c('div', {
      staticClass: "input-group-append"
    }, [_c('FormItem', {
      staticClass: "bg-gray",
      attrs: {
        "id": "boundRegexType-".concat(index),
        "type": "select",
        "options": [{
          label: 'Capture',
          value: 'capture'
        }, {
          label: 'Replace',
          value: 'replace'
        }]
      },
      model: {
        value: bound.regexType,
        callback: function callback($$v) {
          _vm.$set(bound, "regexType", $$v);
        },
        expression: "bound.regexType"
      }
    })], 1)], 1)])], 1) : _vm._e(), _c('label', [_vm._v("Lines")]), _vm.boundPreviews[bound.name] ? _c('div', [_c('table', {
      staticClass: "table table-borderless table-striped"
    }, [_c('tbody', _vm._l(_vm.boundPreviews[bound.name].lines, function (line, lineIndex) {
      return _c('tr', {
        key: lineIndex
      }, [_c('td', [_vm._v(_vm._s(line.text))]), _c('td', {
        staticClass: "text-right align-middle"
      }, [_c('span', {
        staticClass: "badge badge-primary"
      }, [_vm._v(_vm._s(parseFloat(line.confidence).toFixed(2)) + "%")])])]);
    }), 0)])]) : _c('div', {
      staticClass: "alert alert-warning mb-0",
      attrs: {
        "role": "alert"
      }
    }, [_c('p', {
      staticClass: "mb-0"
    }, [_vm._v("No lines within bounds.")])])], 1)])]);
  }), _c('button', {
    staticClass: "btn btn-primary btn-block",
    attrs: {
      "disabled": _vm.loading.saveBounds
    },
    on: {
      "click": _vm.onClickSaveBounds
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-save"
  }), _vm._v(" " + _vm._s(_vm.loading.saveBounds ? 'Saving...' : 'Save Changes') + " ")])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }