export const applyRegexByType = (text, regex, type = 'capture') => {
  let parsedText = 'N/A';

  if (type === 'capture') {
    const exec = regex.exec(text);
    if (exec) parsedText = exec.slice(1).join('');
  }

  if (type === 'replace') {
    parsedText = text.replace(regex, '');
  }

  if (type === 'match') {
    return text.match(regex);
  }

  return parsedText;
};

export const getLinesWithinPage = (lines, pageNumber) => {
  return lines ? lines.filter(line => line.page === pageNumber) : [];
};

export const getCellsWithinPage = (cells, pageNumber) => {
  return cells ? cells.filter(cell => cell.page === pageNumber) : [];
};

export const getPagesFromRules = (template, invoiceResults) => {
  if (!template || !template.pages || !invoiceResults) return [];

  const pages = [];

  for (let pageIndex = 0; pageIndex <= invoiceResults.totalPages; pageIndex++) {
    const pageLines = getLinesWithinPage(invoiceResults.rawLines, pageIndex + 1);
    const pageCells = getCellsWithinPage(invoiceResults.rawCells, pageIndex + 1);
    const linesMatched = [];

    const namedPage = template.pages.find(templatePage => {
      const matchedRules = templatePage.rules.filter(rule => {
        if (!rule.value) return false;

        const regex = new RegExp(rule.value, 'ig');
        const joinedInvoiceText = pageLines.map(l => l.text).join(' ');
        return joinedInvoiceText.match(regex);

        // const matchedLines = pageLines.filter(line => {
        //   try {
        //     const regex = new RegExp(rule.value, 'ig');
        //     return line.text.match(regex);
        //   } catch (e) {
        //     return false;
        //   }
        // });

        // if (matchedLines.length > 0) {
        //   linesMatched.push(...matchedLines);
        //   return true;
        // }

        // return false;
      });

      if (templatePage.matchType === 'all') {
        return matchedRules.length === templatePage.rules.length;
      }

      return matchedRules.length > 0;
    });

    const pageClassification = namedPage ? { name: namedPage.name } : { name: null };

    if (pageClassification.name) {
      pages.push({
        ...pageClassification,
        page: pageIndex + 1,
        lines: pageLines,
        cells: pageCells,
        linesMatched
      });
    }
  }

  return pages;
};

export const getLinesWithinPageByName = (template, invoiceResults, pageName, pageNumber = 1) => {
  const pages = getPagesFromRules(template, invoiceResults);
  const filteredPages = pages.filter(page => page.name === pageName);

  const page = filteredPages.length > 1 ? filteredPages.find(page => page.page === pageNumber) : filteredPages[0];

  return page ? page.lines : [];
};

export const getCellsWithinPageByName = (template, invoiceResults, pageName, pageNumber = 1) => {
  const pages = getPagesFromRules(template, invoiceResults);
  const filteredPages = pages.filter(page => page.name === pageName);

  const page = filteredPages.length > 1 ? filteredPages.find(page => page.page === pageNumber) : filteredPages[0];

  return page ? page.cells : [];
};

export const getBoundsWithinPage = (template, invoiceResults, pageNumber) => {
  if (!template || !template.bounds || !invoiceResults) return [];

  const pagesFromRules = getPagesFromRules(template, invoiceResults);

  const pageFromRules = pagesFromRules.find(page => page.page === pageNumber);

  if (!pageFromRules) return [];

  return template.bounds.filter(bound => bound.page === pageFromRules.name);
};

export const getLinesWithinBounds = (lines, bounds) => {
  // Use user bounds

  const currentLeft = bounds.left;
  const currentTop = bounds.top;
  const currentRight = bounds.left + bounds.width;
  const currentBottom = bounds.top + bounds.height;

  return lines.filter(line => {
    const left = line.polygon.left;
    const top = line.polygon.top;
    const right = line.polygon.width + left;
    const bottom = line.polygon.height + top;

    return currentLeft < left && currentTop < top && currentRight > right && currentBottom > bottom;
  });
};

export const getBoundTablePreview = (pageCells, bound) => {
  let error = '';
  let cell = {};

  if (!bound.regex) {
    return { lines: [], error: 'Regex required' };
  }

  try {
    let skip = bound.regexSkip || 0;

    const regex = new RegExp(bound.regex, 'ig');
    cell = pageCells.find(cell => {
      const isMatch = applyRegexByType(cell.text, regex, 'match');

      if (skip > 0 && isMatch) {
        skip = skip - 1;

        return false;
      }

      return isMatch;
    });
  } catch (e) {
    error = 'Invalid regex';
    return { lines: [], error };
  }

  if (!cell) {
    return { lines: [], error };
  }

  let cells = [];

  const offset = parseInt(bound.cellOffset || 1, 10);
  const offset2 = parseInt(bound.cellOffset2 || 0, 10);

  const tableRowConditional = cell => {
    if (!bound.tableRowRegex) return true;

    const rowText = pageCells
      .filter(c => c.table === cell.table && c.row === cell.row)
      .map(c => c.text)
      .join(' ');

    const regex = new RegExp(bound.tableRowRegex, 'ig');

    return applyRegexByType(rowText, regex, 'match');
  };

  if (cell && bound.cellDirection.includes('row') && (bound.cellDirection2 === 'row' || !bound.cellDirection2)) {
    const combinedOffset = offset + offset2;
    const conditional = c => (combinedOffset >= 0 ? c.row >= cell.row + combinedOffset : c.row <= cell.row + combinedOffset);

    let capturedCells = pageCells
      .filter(c => c.table === cell.table && conditional(c) && c.col === cell.col && tableRowConditional(c))
      .map(c => ({ text: c.text, confidence: c.confidence }));

    if (capturedCells.length === 0) capturedCells = [{ text: null }];

    cells.push(...capturedCells);
  } else if (cell && bound.cellDirection.includes('column') && (bound.cellDirection2 === 'column' || !bound.cellDirection2)) {
    const combinedOffset = offset + offset2;
    const conditional = c => (combinedOffset >= 0 ? c.col >= cell.col + combinedOffset : c.col <= cell.col + combinedOffset);

    let capturedCells = pageCells
      .filter(c => c.table === cell.table && c.row === cell.row && conditional(c) && tableRowConditional(c))
      .map(c => ({ text: c.text, confidence: c.confidence }));

    if (capturedCells.length === 0) capturedCells = [{ text: null }];

    cells.push(...capturedCells);
  } else if (cell && bound.cellDirection.includes('column') && bound.cellDirection2 === 'row') {
    const conditional = c => (offset2 >= 0 ? c.row >= cell.row + offset2 : c.row <= cell.row + offset2);

    let capturedCells = pageCells
      .filter(c => c.table === cell.table && conditional(c) && c.col === cell.col + offset && tableRowConditional(c))
      .map(c => ({ text: c.text, confidence: c.confidence }));

    if (capturedCells.length === 0) capturedCells = [{ text: null }];

    cells.push(...capturedCells);
  } else if (cell && bound.cellDirection.includes('row') && bound.cellDirection2 === 'column') {
    const conditional = c => (offset2 >= 0 ? c.col >= cell.col + offset2 : c.col <= cell.col + offset2);

    let capturedCells = pageCells
      .filter(c => c.table === cell.table && c.row === cell.row + offset && conditional(c) && tableRowConditional(c))
      .map(c => ({ text: c.text, confidence: c.confidence }));

    if (capturedCells.length === 0) capturedCells = [{ text: null }];

    cells.push(...capturedCells);
  }

  if (bound.tableCellsStopRegex) {
    let stop = false;

    cells = cells.filter(cell => {
      const cellsStopRegex = new RegExp(bound.tableCellsStopRegex, 'ig');

      if (!cell.text) return false;

      const isMatch = cell.text.match(cellsStopRegex);

      if (isMatch) stop = true;

      return !stop;
    });
  }

  if (bound.tableTypeRegex) {
    const originalCells = [...cells];
    console.log('originalCells', originalCells);

    originalCells.forEach(cell => {
      const regex = new RegExp(bound.tableTypeRegex, 'ig');
      const newCells = cell.text.match(regex);

      console.log('regex', bound.tableTypeRegex);
      console.log('newCells', newCells);

      cells = (newCells || []).map(text => ({
        ...cell,
        text
      }));
      console.log('cells', cells);
    });
  }

  return {
    lines: cells,
    error
  };
};

export const getBoundPreview = (lines, bound) => {
  if (!bound || !lines || lines.length === 0) return { lines: [], error: '' };

  if (bound.type === 'table') return getBoundTablePreview(lines, bound);

  let previewLines = getLinesWithinBounds(lines, bound);
  if (!previewLines || previewLines.length === 0) return { lines: [], error: '' };

  let error = '';

  if (bound.group === 'combine') {
    let combinedLine = previewLines.map(l => l.text).join(' ');

    if (bound.regex) {
      try {
        const regex = new RegExp(bound.regex, 'ig');
        combinedLine = applyRegexByType(combinedLine, regex, bound.regexType);
      } catch (e) {
        error = 'Invalid regex';
      }
    }

    previewLines = [
      {
        text: combinedLine,
        confidence: previewLines.reduce((sum, line) => (parseFloat(line.confidence) < sum ? parseFloat(line.confidence) : sum), 100)
      }
    ];
  } else if (bound.group === 'seperate' && bound.regex) {
    try {
      const regex = new RegExp(bound.regex, 'ig');

      previewLines = previewLines.filter(line => applyRegexByType(line.text, regex, 'match'));
    } catch (e) {
      error = 'Invalid regex';
    }
  }

  return { lines: previewLines, error };
};

export const getValuePreview = (lines, value) => {
  if (!value || !lines || lines.length === 0) return 'N/A';

  const line = lines.find(line => {
    try {
      const regex = new RegExp(value.lineRegex, 'ig');
      return !!line.text.match(regex);
    } catch (e) {
      return false;
    }
  });

  if (!line) return 'N/A';

  if (!value.regex) return line.text;

  try {
    const regex = new RegExp(value.regex, 'ig');

    return applyRegexByType(line.text, regex, value.regexType);
  } catch (e) {
    return 'N/A';
  }
};

export const getRatePreview = (lines, type, rate) => {
  let preview = 'N/A';

  if (!rate || !type || !lines || lines.length === 0) return preview;

  const line = lines.find(line => {
    try {
      const regex = new RegExp(rate[`${type}LineRegex`], 'ig');

      return line.text.match(regex);
    } catch (e) {
      return false;
    }
  });

  if (!line) return preview;

  try {
    const regex = new RegExp(rate[`${type}Regex`], 'ig');

    preview = applyRegexByType(line.text, regex, rate[`${type}RegexType`]);
  } catch (e) {
    preview = 'N/A (Invalid Regex)';
  }

  return preview;
};
