var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center mb-4"
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v("Pages")]), _c('button', {
    staticClass: "btn btn-primary btn-sm",
    class: {
      disabled: _vm.pageForm.show
    },
    attrs: {
      "disabled": _vm.pageForm.show
    },
    on: {
      "click": _vm.onClickShowNewPage
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-plus"
  }), _vm._v(" Add Page ")])]), _vm.pageForm.started ? _c('div', {
    staticClass: "block block-rounded mb-4"
  }, [_c('div', {
    staticClass: "block-header",
    class: {
      'border-bottom': _vm.pageForm.show
    }
  }, [_c('a', {
    staticClass: "text-success",
    attrs: {
      "data-toggle": "collapse",
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onClickShowNewPage($event);
      }
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.pageForm.name || 'New Page'))]), _vm._v(" - " + _vm._s(_vm.pageForm.rules.length) + " rule" + _vm._s(_vm.pageForm.rules.length === 1 ? '' : 's'))]), _c('span', [_c('button', {
    staticClass: "btn btn-link font-w600",
    on: {
      "click": _vm.onClickDiscardNewPage
    }
  }, [_vm._v("Discard")])])]), _c('div', {
    staticClass: "collapse",
    class: {
      show: _vm.pageForm.show
    }
  }, [_c('div', {
    staticClass: "block-content block-content-full"
  }, [_c('FormGroup', {
    attrs: {
      "id": "pageName",
      "label": "Page Name",
      "type": "text",
      "is-alt": true
    },
    model: {
      value: _vm.pageForm.name,
      callback: function callback($$v) {
        _vm.$set(_vm.pageForm, "name", $$v);
      },
      expression: "pageForm.name"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "pageMatchType",
      "type": "radio",
      "label": "Match Type",
      "options": [{
        label: 'All',
        value: 'all'
      }, {
        label: 'Any',
        value: 'any'
      }]
    },
    model: {
      value: _vm.pageForm.matchType,
      callback: function callback($$v) {
        _vm.$set(_vm.pageForm, "matchType", $$v);
      },
      expression: "pageForm.matchType"
    }
  }), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center mb-4"
  }, [_c('label', {
    staticClass: "mb-0"
  }, [_vm._v("Rules")]), _c('button', {
    staticClass: "btn btn-primary btn-sm",
    on: {
      "click": _vm.onClickNewAddRule
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-plus"
  }), _vm._v(" Add Rule")])]), _vm.pageForm.rules.length > 0 ? _c('div', _vm._l(_vm.pageForm.rules, function (rule, ruleIndex) {
    return _c('div', {
      key: ruleIndex,
      staticClass: "block block-rounded bg-body"
    }, [_c('div', {
      staticClass: "block-content block-content-full mb-4"
    }, [rule.isEdit ? _c('div', {
      staticClass: "input-group"
    }, [_c('FormItem', {
      attrs: {
        "id": "rule".concat(ruleIndex, "-value"),
        "placeholder": "Enter a regex rule...",
        "type": "text"
      },
      model: {
        value: rule.newValue,
        callback: function callback($$v) {
          _vm.$set(rule, "newValue", $$v);
        },
        expression: "rule.newValue"
      }
    }), _c('div', {
      staticClass: "input-group-append"
    }, [_c('button', {
      staticClass: "btn btn-primary",
      on: {
        "click": function click() {
          return _vm.onClickNewConfirmRule(ruleIndex);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-check"
    })]), _c('button', {
      staticClass: "btn btn-danger",
      on: {
        "click": function click() {
          return _vm.onClickNewCancelRule(ruleIndex);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-times"
    })])])], 1) : _c('div', {
      staticClass: "d-flex justify-content-between align-items-center"
    }, [_c('strong', [_vm._v(_vm._s(rule.value))]), _c('span', [_c('button', {
      staticClass: "btn btn-primary btn-sm mr-3",
      on: {
        "click": function click() {
          return _vm.onClickNewEditRule(ruleIndex);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-pencil"
    })]), _c('button', {
      staticClass: "btn btn-danger btn-sm",
      on: {
        "click": function click() {
          return _vm.onClickNewDeleteRule(ruleIndex);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-trash"
    })])])])])]);
  }), 0) : _c('div', {
    staticClass: "alert alert-warning",
    attrs: {
      "role": "alert"
    }
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v("No rules set.")])])], 1)])]) : _vm._e(), _vm._l(_vm.pages, function (page, index) {
    return _c('div', {
      key: index,
      staticClass: "block block-rounded mb-4"
    }, [_c('div', {
      staticClass: "block-header",
      class: {
        'border-bottom': page.show
      }
    }, [_c('a', {
      class: {
        'text-danger': page.delete,
        'text-strike': page.delete
      },
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return function () {
            return _vm.onClickShowExisting(index);
          }($event);
        }
      }
    }, [_c('strong', [_vm._v(_vm._s(page.name))]), _vm._v(" "), _c('span', {
      staticClass: "badge badge-success ml-2"
    }, [_vm._v(_vm._s(page.rules.length))])]), _c('span', [!page.delete ? _c('button', {
      staticClass: "btn btn-link font-w600 mr-3",
      on: {
        "click": function click() {
          return _vm.onClickShowExisting(index);
        }
      }
    }, [_vm._v("Manage")]) : _vm._e(), !page.delete ? _c('button', {
      staticClass: "btn btn-link font-w600 text-danger",
      on: {
        "click": function click() {
          return _vm.onClickDeleteExisting(index);
        }
      }
    }, [_vm._v("Delete")]) : _vm._e(), page.delete ? _c('button', {
      staticClass: "btn btn-link font-w600",
      on: {
        "click": function click() {
          return _vm.onClickDeleteExisting(index);
        }
      }
    }, [_vm._v("Undo")]) : _vm._e()])]), _c('div', {
      staticClass: "collapse",
      class: {
        show: page.show
      }
    }, [_c('div', {
      staticClass: "block-content block-content-full"
    }, [_c('FormGroup', {
      attrs: {
        "id": "pageName",
        "label": "Page Name",
        "type": "text",
        "is-alt": true
      },
      model: {
        value: page.name,
        callback: function callback($$v) {
          _vm.$set(page, "name", $$v);
        },
        expression: "page.name"
      }
    }), _c('FormGroup', {
      attrs: {
        "id": "pageMatchType",
        "type": "radio",
        "label": "Match Type",
        "options": [{
          label: 'All',
          value: 'all'
        }, {
          label: 'Any',
          value: 'any'
        }]
      },
      model: {
        value: page.matchType,
        callback: function callback($$v) {
          _vm.$set(page, "matchType", $$v);
        },
        expression: "page.matchType"
      }
    }), _c('div', {
      staticClass: "d-flex justify-content-between align-items-center mb-4"
    }, [_c('label', {
      staticClass: "mb-0"
    }, [_vm._v("Rules")]), _c('button', {
      staticClass: "btn btn-primary btn-sm",
      on: {
        "click": function click() {
          return _vm.onClickExistingAddRule(index);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-plus"
    }), _vm._v(" Add Rule")])]), page.rules.length > 0 ? _c('div', _vm._l(page.rules, function (rule, ruleIndex) {
      return _c('div', {
        key: ruleIndex,
        staticClass: "block block-rounded bg-body"
      }, [_c('div', {
        staticClass: "block-content block-content-full mb-4"
      }, [rule.isEdit ? _c('div', {
        staticClass: "input-group"
      }, [_c('FormItem', {
        attrs: {
          "id": "rule".concat(ruleIndex, "-value"),
          "placeholder": "Enter a regex rule...",
          "type": "text"
        },
        model: {
          value: rule.newValue,
          callback: function callback($$v) {
            _vm.$set(rule, "newValue", $$v);
          },
          expression: "rule.newValue"
        }
      }), _c('div', {
        staticClass: "input-group-append"
      }, [_c('button', {
        staticClass: "btn btn-primary",
        on: {
          "click": function click() {
            return _vm.onClickExistingConfirmRule(index, ruleIndex);
          }
        }
      }, [_c('i', {
        staticClass: "fa fa-fw fa-check"
      })]), _c('button', {
        staticClass: "btn btn-danger",
        on: {
          "click": function click() {
            return _vm.onClickExistingCancelRule(index, ruleIndex);
          }
        }
      }, [_c('i', {
        staticClass: "fa fa-fw fa-times"
      })])])], 1) : _c('div', {
        staticClass: "d-flex justify-content-between align-items-center"
      }, [_c('strong', [_vm._v(_vm._s(rule.value))]), _c('span', [_c('button', {
        staticClass: "btn btn-primary btn-sm mr-3",
        on: {
          "click": function click() {
            return _vm.onClickExistingEditRule(index, ruleIndex);
          }
        }
      }, [_c('i', {
        staticClass: "fa fa-fw fa-pencil"
      })]), _c('button', {
        staticClass: "btn btn-danger btn-sm",
        on: {
          "click": function click() {
            return _vm.onClickDeleteExistingRule(index, ruleIndex);
          }
        }
      }, [_c('i', {
        staticClass: "fa fa-fw fa-trash"
      })])])])])]);
    }), 0) : _c('div', {
      staticClass: "alert alert-warning",
      attrs: {
        "role": "alert"
      }
    }, [_c('p', {
      staticClass: "mb-0"
    }, [_vm._v("No rules set.")])])], 1)])]);
  }), _c('button', {
    staticClass: "btn btn-primary btn-block",
    attrs: {
      "disabled": _vm.loading.savePages
    },
    on: {
      "click": _vm.onClickSavePages
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-save"
  }), _vm._v(" " + _vm._s(_vm.loading.savePages ? 'Saving...' : 'Save Changes') + " ")])], 2);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "block block-rounded"
  }, [_c('div', {
    staticClass: "block-content mb-4"
  }, [_c('h4', [_vm._v("Pages Classification")]), _c('p', [_vm._v("Manage rules that determine what a page contains.")])])]);
}]

export { render, staticRenderFns }