<template>
  <div>
    <div>
      <ul class="nav nav-tabs nav-tabs-block">
        <li v-for="tab in tabs" :key="tab" class="nav-item text-capitalize">
          <a class="nav-link" href="#" :class="{ active: selectedTab === tab }" @click="() => onClickTab(tab)">{{ tab }}</a>
        </li>
        <li class="nav-item ml-auto">
          <span v-if="invoiceLoading.getAnalysis" class="nav-link d-inline-block">
            <i class="fa fa-fw fa-spinner fa-spin"></i>
          </span>
          <div class="dropdown d-inline-block">
            <button
              type="button"
              class="btn-block-option font-w600 dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ template.version }}
            </button>
            <div class="dropdown-menu dropdown-menu-right" style="">
              <a
                v-for="t in templates"
                :key="t._id"
                class="dropdown-item"
                href="#"
                :class="{ active: template._id === t._id }"
                @click.prevent="() => setTemplate(t)"
                >{{ t.version }}</a
              >
              <div role="separator" class="dropdown-divider"></div>
              <a class="dropdown-item" href="#" @click.prevent="onClickNewTemplate"><i class="fa fa-fw fa-plus" /> Create New</a>
              <a class="dropdown-item" href="#" @click.prevent="onClickCloneTemplate"><i class="fa fa-fw fa-clone" /> Duplicate</a>
            </div>
          </div>
          <a class="nav-link d-inline-block" href="#" :class="{ disabled: invoiceLoading.getAnalysis }" @click.prevent="onClickPrevPreview">
            <i class="fa fa-fw fa-arrow-left"></i>
          </a>
          <a class="nav-link d-inline-block" href="#" :class="{ disabled: invoiceLoading.getAnalysis }" @click.prevent="onClickNextPreview">
            <i class="fa fa-fw fa-arrow-right"></i>
          </a>
          <a class="nav-link d-inline-block" href="#" :class="{ disabled: invoiceLoading.getAnalysis }" @click.prevent="onClickRefreshPreview">
            <i class="fa fa-fw fa-redo"></i>
          </a>
          <a
            class="nav-link d-inline-block text-danger"
            href="#"
            :class="{ disabled: invoiceLoading.getAnalysis }"
            @click.prevent="onClickResetPreview"
          >
            <i class="fa fa-fw fa-times"></i>
          </a>
        </li>
      </ul>
      <div v-if="isLoading" class="tab-content">
        <div class="d-flex justify-content-center py-5">
          <div class="spinner-border spinner-lg text-secondary my-5" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
      <div v-else-if="noTemplate" class="tab-content p-0 mt-4">
        <div class="alert alert-warning mb-0" role="alert">
          <p class="mb-0">No template found for this supplier.</p>
        </div>
      </div>
      <div v-else class="tab-content p-0">
        <div class="tab-pane py-0 px-3" :class="{ active: selectedTab === 'editor' }">
          <div class="row">
            <div class="col-xl-6 bg-white py-4">
              <div v-if="loadingInvoice">
                <div class="d-flex justify-content-center py-5">
                  <div class="spinner-border spinner-lg text-secondary my-5" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
              <div v-if="!currentInvoice.fileName && !loadingInvoice">
                <table v-if="invoices && invoices.length > 0" class="table table-borderless">
                  <thead>
                    <tr>
                      <th>File Name</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="invoice in invoices" :key="invoice.jobId">
                      <td>{{ invoice.fileName }}</td>
                      <td><button class="btn btn-primary" @click="() => onClickLoadInvoice(invoice)">Load</button></td>
                    </tr>
                  </tbody>
                </table>
                <div v-else class="alert alert-warning mb-0" role="alert">
                  <p class="mb-0">No invoices found for this supplier.</p>
                </div>
              </div>
              <div v-else-if="!loadingInvoice">
                <div class="d-flex justify-content-between align-items-middle mb-3">
                  <h4 class="mb-0">{{ currentInvoice.fileName }}</h4>
                  <button class="btn btn-link text-danger" @click="() => onClickUnloadInvoice(invoice)">
                    <i class="fa fa-fw fa-times" />
                  </button>
                </div>
                <div class="d-flex justify-content-between align-items-center px-4">
                  <ul class="pagination">
                    <li class="page-item" :class="{ disabled: preview.currentPage === 1 }">
                      <button class="page-link" @click.prevent="prevPreviewPage">
                        <i class="fa fa-angle-left"></i>
                      </button>
                    </li>
                    <li class="page-item" :class="{ disabled: preview.currentPage === preview.totalPages }">
                      <button class="page-link" @click.prevent="nextPreviewPage">
                        <i class="fa fa-angle-right"></i>
                      </button>
                    </li>
                  </ul>
                  <div class="font-w700">Page {{ preview.currentPage }} of {{ preview.totalPages }}</div>
                </div>
                <div class="pdf-preview mb-4">
                  <div
                    ref="previewBox"
                    class="bounds-wrapper ribbon ribbon-success ribbon-left text-uppercase"
                    @mousedown="onStartBound"
                    @mouseup="onEndBound"
                    @mousemove="onUpdateBound"
                  >
                    <div v-if="currentPageFromRules" class="ribbon-box">{{ currentPageFromRules.name }}</div>

                    <div v-if="currentPageRawLines && currentPageRawLines.length > 0" class="line-bounds">
                      <div v-if="selectedSubTab == 'pages' && currentPageFromRules">
                        <div
                          v-for="(line, lineIdx) in currentPageFromRules.linesMatched"
                          :key="lineIdx"
                          class="line-bound line-bound--page"
                          :style="{
                            width: line.polygon.width * 100 + 1 + '%',
                            height: line.polygon.height * 100 + 0.5 + '%',
                            top: line.polygon.top * 100 - 0.25 + '%',
                            left: line.polygon.left * 100 - 0.5 + '%'
                          }"
                          :title="line.text"
                        ></div>
                      </div>
                      <div v-if="selectedSubTab == 'supplier'">
                        <div
                          v-for="(line, lineIdx) in currentPageSupplierRuleLines"
                          :key="lineIdx"
                          class="line-bound line-bound--supplier"
                          :style="{
                            width: line.polygon.width * 100 + 1 + '%',
                            height: line.polygon.height * 100 + 0.5 + '%',
                            top: line.polygon.top * 100 - 0.25 + '%',
                            left: line.polygon.left * 100 - 0.5 + '%'
                          }"
                          :title="line.text"
                        ></div>
                      </div>
                      <div v-if="selectedSubTab == 'bounds' && settings.showLines">
                        <div
                          v-for="(line, lineIdx) in currentPageRawLines"
                          :key="lineIdx"
                          class="line-bound"
                          :style="{
                            width: line.polygon.width * 100 + 1 + '%',
                            height: line.polygon.height * 100 + 0.5 + '%',
                            top: line.polygon.top * 100 - 0.25 + '%',
                            left: line.polygon.left * 100 - 0.5 + '%'
                          }"
                          :title="line.text"
                        ></div>
                      </div>
                      <div v-if="selectedSubTab == 'bounds' && settings.allowDrawing">
                        <div
                          class="line-bound line-bound--user"
                          :style="{
                            width: userBound.width + 'px',
                            height: userBound.height + 'px',
                            top: userBound.top + 'px',
                            left: userBound.left + 'px'
                          }"
                        ></div>
                      </div>
                      <div v-if="(selectedSubTab == 'bounds' && settings.showBounds) || selectedSubTab == 'values'">
                        <div
                          v-for="bound in currentPageBounds.filter(b => b.type !== 'table')"
                          :key="bound.name"
                          class="line-bound line-bound--bound"
                          :style="{
                            width: bound.width * 100 + 1 + '%',
                            height: bound.height * 100 + 0.5 + '%',
                            top: bound.top * 100 - 0.25 + '%',
                            left: bound.left * 100 - 0.5 + '%'
                          }"
                          :title="bound.name"
                        >
                          <span class="line-bound-label">{{ bound.name }}</span>
                        </div>
                      </div>
                      <div v-if="(selectedSubTab == 'bounds' && settings.showCells) || selectedSubTab == 'values'">
                        <div
                          v-for="(cell, cellIdx) in currentPageCells"
                          :key="cellIdx"
                          class="line-bound line-bound--cell"
                          :style="{
                            width: cell.polygon.width * 100 + 1 + '%',
                            height: cell.polygon.height * 100 + 0.5 + '%',
                            top: cell.polygon.top * 100 - 0.25 + '%',
                            left: cell.polygon.left * 100 - 0.5 + '%'
                          }"
                        >
                          <!-- <span class="line-bound-label">Table {{ tableIdx + 1 }}</span> -->
                        </div>
                      </div>
                    </div>
                    <PdfViewer :src="preview.url" :page="preview.currentPage" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6 px-4">
              <ul class="nav nav-pills nav-justified push mt-3">
                <li class="nav-item mr-1">
                  <a class="nav-link" href="#" :class="{ active: selectedSubTab === 'version' }" @click.prevent="() => onClickSubTab('version')"
                    ><i class="fa fa-fw fa-clone mr-2"></i> Version</a
                  >
                </li>
                <li class="nav-item mr-1">
                  <a class="nav-link" href="#" :class="{ active: selectedSubTab === 'supplier' }" @click.prevent="() => onClickSubTab('supplier')"
                    ><i class="fa fa-fw fa-box mr-2"></i> Supplier</a
                  >
                </li>
                <li class="nav-item mr-1">
                  <a class="nav-link" href="#" :class="{ active: selectedSubTab === 'pages' }" @click.prevent="() => onClickSubTab('pages')"
                    ><i class="fa fa-fw fa-layer-group mr-2"></i> Pages</a
                  >
                </li>
                <li class="nav-item mr-1">
                  <a class="nav-link" href="#" :class="{ active: selectedSubTab === 'bounds' }" @click.prevent="() => onClickSubTab('bounds')"
                    ><i class="fa fa-fw fa-draw-square mr-2"></i> Bounds</a
                  >
                </li>
                <li class="nav-item mr-1">
                  <a class="nav-link" href="#" :class="{ active: selectedSubTab === 'values' }" @click.prevent="() => onClickSubTab('values')"
                    ><i class="fa fa-fw fa-font-case mr-2"></i> Values</a
                  >
                </li>
              </ul>
              <keep-alive>
                <component
                  :is="subTabMap[selectedSubTab]"
                  :user-bound="userBound"
                  :current-lines="currentPageRawLines"
                  :current-cells="currentPageCells"
                  :preview-box="$refs.previewBox"
                  :current-page="currentPageFromRules"
                  :current-invoice="currentInvoice"
                  @updatePreview="onRefreshTemplate"
                />
              </keep-alive>
            </div>
          </div>
        </div>
        <div class="tab-pane bg-white p-3" :class="{ active: selectedTab === 'preview' }">
          <InvoiceResultsDetailed v-if="currentInvoice.detailedResults" :invoice="currentInvoice" :supplier="supplier" :invoice-keys="invoiceKeys" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import PdfViewer from 'vue-pdf';

import { getLinesWithinPage, getCellsWithinPage, getPagesFromRules, getBoundsWithinPage } from '../../../lib/templateHelpers';

import SupplierTemplateBounds from './SupplierTemplateBounds';
import SupplierTemplatePages from './SupplierTemplatePages';
import SupplierTemplateSuppliers from './SupplierTemplateSuppliers';
import SupplierTemplateVersions from './SupplierTemplateVersions';
import SupplierTemplateValues from './SupplierTemplateValues';
import FormGroup from '@/components/FormGroup';
import InvoiceResultsDetailed from '@/components/InvoiceResultsDetailed';

export default {
  name: 'SupplierTemplate',
  components: {
    FormGroup,
    PdfViewer,
    SupplierTemplateBounds,
    SupplierTemplatePages,
    SupplierTemplateSuppliers,
    SupplierTemplateValues,
    SupplierTemplateVersions,
    InvoiceResultsDetailed
  },
  data() {
    return {
      selectedTab: 'editor',
      tabs: ['editor', 'preview'],
      selectedSubTab: 'version',
      boundSelector: {
        start: { x: 0, y: 0 },
        end: { x: 0, y: 0 },
        active: false
      },
      invoices: [],
      currentInvoice: {},
      isLoading: false,
      subTabMap: {
        version: 'SupplierTemplateVersions',
        supplier: 'SupplierTemplateSuppliers',
        pages: 'SupplierTemplatePages',
        bounds: 'SupplierTemplateBounds',
        values: 'SupplierTemplateValues'
      },
      loadingInvoice: false,
      noTemplate: false
    };
  },
  computed: {
    ...mapGetters({
      template: 'invoiceTemplate/template',
      templates: 'invoiceTemplate/templates',
      error: 'invoiceTemplate/error',
      invoiceLoading: 'invoiceTemplate/loading',
      supplier: 'supplier/supplier',
      supplierLoading: 'supplier/loadingAction',
      invoiceKeys: 'invoiceTemplate/invoiceKeys',
      preview: 'invoiceTemplate/preview',
      settings: 'invoiceTemplate/settings'
    }),
    currentPageRawLines() {
      return this.currentInvoice.detailedResults ? getLinesWithinPage(this.currentInvoice.detailedResults.rawLines, this.preview.currentPage) : [];
    },
    currentPageCells() {
      return this.currentInvoice.detailedResults ? getCellsWithinPage(this.currentInvoice.detailedResults.rawCells, this.preview.currentPage) : [];
    },
    currentPageSupplierRuleLines() {
      return this.currentPageRawLines.filter(line =>
        this.template.supplierRules.some(rule => {
          try {
            const regex = new RegExp(rule.value, 'ig');
            return line.text.match(regex);
          } catch (e) {
            return false;
          }
        })
      );
    },
    currentPageBounds() {
      return getBoundsWithinPage(this.template, this.currentInvoice.detailedResults, this.preview.currentPage);
    },
    userBound() {
      // if (!this.bound.active) return { width: 0, height: 0, top: 0, left: 0 };
      const diffX = this.boundSelector.start.x - this.boundSelector.end.x;
      const diffY = this.boundSelector.start.y - this.boundSelector.end.y;

      const results = {};

      results.width = Math.abs(diffX);
      results.left = diffX < 0 ? this.boundSelector.start.x : this.boundSelector.start.x - diffX;

      results.height = Math.abs(diffY);
      results.top = diffY < 0 ? this.boundSelector.start.y : this.boundSelector.start.y - diffY;

      if (this.$refs.previewBox) {
        const totalWidth = this.$refs.previewBox.clientWidth;
        const totalHeight = this.$refs.previewBox.clientHeight;

        results.leftPct = results.left / totalWidth;
        results.topPct = results.top / totalHeight;
        results.widthPct = results.width / totalWidth;
        results.heightPct = results.height / totalHeight;
      }

      return results;
    },

    // Determine what the page is using page rules
    currentPageFromRules() {
      const classifiedPages = getPagesFromRules(this.template, this.currentInvoice.detailedResults);

      return classifiedPages.find(classifiedPage => classifiedPage.page === this.preview.currentPage);
    }
  },
  async created() {
    // Get invoice keys
    if (this.invoiceKeys.length === 0) {
      await this.getInvoiceKeys();
    }

    this.loadTemplateData();

    if (this.$route.query.templateTab) {
      this.selectedTab = this.$route.query.templateTab;
    }

    if (this.$route.query.templateTab) {
      this.selectedSubTab = this.$route.query.templateSubTab;
    }
  },
  destroyed() {
    this.resetAnalysis();
  },
  methods: {
    ...mapActions({
      refresh: 'invoiceUpload/refresh',
      download: 'invoice/download',
      getInvoiceKeys: 'invoiceTemplate/getInvoiceKeys',
      getSupplier: 'supplier/get',
      getTemplates: 'invoiceTemplate/getTemplates',
      createTemplate: 'invoiceTemplate/createTemplate',
      listInvoices: 'invoice/list'
    }),
    ...mapMutations({
      setTemplate: 'invoiceTemplate/SET_TEMPLATE',
      updateInvoicePreview: 'invoiceTemplate/UPDATE_PREVIEW',
      clearAnalysisPolls: 'invoiceTemplate/CLEAR_POLLS',
      resetAnalysis: 'invoiceTemplate/RESET',
      nextPreviewPage: 'invoiceTemplate/NEXT_PREVIEW_PAGE',
      prevPreviewPage: 'invoiceTemplate/PREV_PREVIEW_PAGE'
    }),
    onClickTab(tab) {
      this.$router.push({
        query: {
          ...this.$route.query,
          templateTab: tab
        }
      });
      this.selectedTab = tab;
    },
    onClickSubTab(tab) {
      this.$router.push({
        query: {
          ...this.$route.query,
          templateSubTab: tab
        }
      });
      this.selectedSubTab = tab;
    },
    async loadTemplateData(paramsInvoiceIds, paramsType) {
      this.isLoading = true;

      const invoiceIds = paramsInvoiceIds || this.$route.query.invoiceIds;
      const type = paramsType || this.$route.meta.type;

      if (invoiceIds) {
        const response = await this.listInvoices({ data: { params: { _id: { $in: invoiceIds.split(',') } } } });
        this.invoices = response.data;
      } else {
        const response = await this.listInvoices({
          data: {
            params: {
              supplierId: this.supplier._id,
              type,
              isManual: false,
              jobId: '$notNull',
              $limit: 10,
              $sort: 'createdAt:-1'
            }
          }
        });
        if (response.data && response.data.length > 0) {
          this.invoices = response.data;
        }
      }

      const supplierTemplates = await this.getTemplates({ supplierId: this.supplier._id, type });

      if (supplierTemplates.length === 0) {
        this.noTemplate = true;
      } else {
        this.noTemplate = false;
        this.setTemplate(supplierTemplates[0]);
      }

      this.isLoading = false;
    },
    async onClickNewTemplate() {
      this.isLoading = true;

      const newTemplate = await this.createTemplate({
        data: {
          supplierId: this.supplier._id,
          version: 'New Version',
          type: this.$route.meta.type
        }
      });

      this.setTemplate(newTemplate);

      await this.getTemplates({ supplierId: this.supplier._id, type: this.$route.meta.type });

      this.isLoading = false;
    },
    async onClickCloneTemplate() {
      this.isLoading = true;

      const cloned = {
        ...this.template,
        version: `${this.template.version} (New)`
      };

      delete cloned._id;

      const newTemplate = await this.createTemplate({
        data: cloned
      });

      this.setTemplate(newTemplate);

      await this.getTemplates({ supplierId: this.supplier._id, type: this.$route.meta.type });

      this.isLoading = false;
    },
    getLinesWithinUserBounds() {
      const totalWidth = this.$refs.previewBox.clientWidth;
      const totalHeight = this.$refs.previewBox.clientHeight;

      const currentLeft = this.userBound.left;
      const currentTop = this.userBound.top;
      const currentRight = this.userBound.left + this.userBound.width;
      const currentBottom = this.userBound.top + this.userBound.height;

      return this.currentPageRawLines.filter(line => {
        const left = line.polygon.left * totalWidth;
        const top = line.polygon.top * totalHeight;
        const right = line.polygon.width * totalWidth + left;
        const bottom = line.polygon.height * totalHeight + top;

        return currentLeft < left && currentTop < top && currentRight > right && currentBottom > bottom;
      });
    },
    async onRefreshTemplate() {
      this.onResetBound();
      // this.currentInvoice = await this.refresh({ id: this.currentInvoice._id });

      this.setTemplate(this.template);
    },
    async onClickNextPreview() {
      const currentInvoiceIdx = this.invoices.findIndex(invoice => invoice.jobId === this.currentInvoice.jobId);

      if (currentInvoiceIdx + 1 === this.invoices.length) return false;

      const nextInvoice = this.invoices[currentInvoiceIdx + 1];
      this.isLoading = true;
      await this.onClickLoadInvoice(nextInvoice);
      this.isLoading = false;
    },
    async onClickPrevPreview() {
      const currentInvoiceIdx = this.invoices.findIndex(invoice => invoice.jobId === this.currentInvoice.jobId);

      if (currentInvoiceIdx === 0) return false;

      const prevInvoice = this.invoices[currentInvoiceIdx - 1];
      this.isLoading = true;
      await this.onClickLoadInvoice(prevInvoice);
      this.isLoading = false;
    },
    async onClickRefreshPreview() {
      if (!this.currentInvoice._id) return false;

      this.isLoading = true;
      this.currentInvoice = await this.refresh({ id: this.currentInvoice._id });
      await this.onClickLoadInvoice(this.currentInvoice);
      this.isLoading = false;
    },
    onClickResetPreview() {
      this.selectedTab = 'editor';
      this.clearAnalysisPolls();
      this.resetAnalysis();
    },
    async onClickLoadInvoice(invoice) {
      this.loadingInvoice = true;

      this.currentInvoice = await this.refresh({ id: invoice._id });

      const previewUrls = await this.download({ id: this.currentInvoice._id, name: 'preview' });

      const pdf = await PdfViewer.createLoadingTask(previewUrls.url).promise;

      this.updateInvoicePreview({
        totalPages: pdf.numPages,
        url: previewUrls.url
      });

      this.loadingInvoice = false;
    },
    async onClickUnloadInvoice() {
      this.currentInvoice = {};

      this.updateInvoicePreview({
        url: ''
      });
    },
    // Bounds
    onStartBound(e) {
      if (!this.settings.allowDrawing) return;

      this.boundSelector.active = true;
      this.boundSelector.start = { x: e.offsetX, y: e.offsetY };
      this.boundSelector.end = { x: e.offsetX, y: e.offsetY };
    },
    onEndBound() {
      if (!this.settings.allowDrawing) return;

      this.boundSelector.active = false;
    },
    onUpdateBound(e) {
      if (!this.settings.allowDrawing) return;

      if (!this.boundSelector.active) return false;
      this.boundSelector.end = { x: e.offsetX, y: e.offsetY };
    },
    onResetBound() {
      this.boundSelector.active = false;
      this.boundSelector.start = { x: 0, y: 0 };
      this.boundSelector.end = { x: 0, y: 0 };
    }
  },
  async beforeRouteLeave(to, from, next) {
    this.isLoading = true;
    this.resetAnalysis();
    this.onResetBound();
    this.currentInvoice = {};
    this.invoices = [];

    await this.getSupplier({ id: to.params.id });
    this.loadTemplateData(to.params.invoiceIds, to.meta.type);

    next();
  }
};
</script>
<style lang="scss">
.template-table {
  cursor: pointer;

  td:hover {
    background-color: #f8f9f9;
  }

  tr.selected td,
  tr.selected td:hover {
    background-color: #e8e186;
  }

  td.selected,
  td.selected:hover {
    background-color: #d2e6da !important;
  }
}

.bounds-wrapper {
  position: relative;
  z-index: 100;

  .line-bound {
    pointer-events: none;
    position: absolute;
    background: #5bc5d638;
    border: 2px solid #37a0b161;
    z-index: 100;

    &--user {
      z-index: 101;
      background: #5bd66438;
      border: 2px solid #37b15161;
    }

    &--page {
      z-index: 101;
      background: #5bd66438;
      border: 2px solid #37b15161;
    }

    &--bound {
      z-index: 101;
      background: #d6885b38;
      border: 2px solid #d26a2d91;
    }

    &--supplier {
      z-index: 101;
      background: #68d65b38;
      border: 2px solid #60b13761;
    }

    &--cell {
      z-index: 101;
      background: #dc10651a;
      border: 2px solid #dc106563;
    }

    &-label {
      background-color: #d26a2d91;
      color: #fff;
      padding: 2px 4px 1px 4px;
      font-size: 13px;
      font-weight: 600;
    }
  }
}
</style>
