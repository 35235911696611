<template>
  <div>
    <div class="block block-rounded">
      <div class="block-content mb-4">
        <h4>Supplier Classification</h4>
        <p>
          When any of the rules below match on any page, the invoice will be classed as <strong>{{ supplier.name }}</strong
          >.
        </p>
      </div>
    </div>

    <div class="d-flex justify-content-between align-items-center mb-4">
      <h5 class="mb-0">Rules</h5>
      <button class="btn btn-primary btn-sm" @click="() => onClickAddRule(index)"><i class="fa fa-fw fa-plus" /> Add Rule</button>
    </div>
    <div v-if="supplierRules.length > 0">
      <div v-for="(rule, index) in supplierRules" :key="index" class="block block-rounded mb-4">
        <div class="block-content block-content-full">
          <div v-if="rule.isEdit" class="input-group">
            <FormItem :id="`rule${index}-value`" v-model="rule.newValue" placeholder="Enter a regex rule..." :is-alt="true" type="text" />
            <div class="input-group-append">
              <button class="btn btn-primary" @click="() => onClickConfirmRule(index)">
                <i class="fa fa-fw fa-check" />
              </button>
              <button class="btn btn-danger" @click="() => onClickCancelRule(index)">
                <i class="fa fa-fw fa-times" />
              </button>
            </div>
          </div>
          <div v-else class="d-flex justify-content-between align-items-center">
            <strong :class="{ 'text-danger': rule.isDelete, 'text-strike': rule.isDelete }">{{ rule.value }}</strong>
            <span>
              <button v-if="!rule.isDelete" class="btn btn-link font-w600 mr-3" @click="() => onClickEditRule(index)">Manage</button>
              <button v-if="!rule.isDelete" class="btn btn-link font-w600 text-danger" @click="() => onClickDeleteRule(index)">Delete</button>
              <button v-if="rule.isDelete" class="btn btn-link font-w600" @click="() => onClickDeleteRule(index)">Undo</button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="alert alert-warning" role="alert">
      <p class="mb-0">No rules set.</p>
    </div>

    <button class="btn btn-primary btn-block mb-4" :disabled="loading.saveSupplierRules" @click="onClickSaveRules">
      <i class="fa fa-fw fa-save" /> {{ loading.saveSupplierRules ? 'Saving...' : 'Save Changes' }}
    </button>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import FormItem from '@/components/FormItem';

export default {
  name: 'SupplierTemplateSuppliers',
  components: {
    FormItem
  },
  props: {
    // template: {
    //   type: Object,
    //   required: true
    // }
  },
  data() {
    return {
      supplierRules: []
    };
  },
  watch: {
    template() {
      this.supplierRules = [...this.template.supplierRules].map(r => ({ ...r, isEdit: false, isDelete: false }));
    }
  },
  mounted() {
    this.supplierRules = [...this.template.supplierRules].map(r => ({ ...r, isEdit: false, isDelete: false }));
  },
  computed: {
    ...mapGetters({
      template: 'invoiceTemplate/template',
      loading: 'invoiceTemplate/loading',
      supplier: 'supplier/supplier'
    })
  },
  methods: {
    ...mapActions({
      save: 'invoiceTemplate/saveSupplierRules'
    }),

    onClickAddRule() {
      this.supplierRules.push({
        type: 'regex',
        value: '',
        newValue: '',
        isEdit: true
      });
    },
    onClickConfirmRule(ruleIndex) {
      this.$set(this.supplierRules[ruleIndex], 'value', this.supplierRules[ruleIndex].newValue);
      this.$set(this.supplierRules[ruleIndex], 'newValue', '');
      this.$set(this.supplierRules[ruleIndex], 'isEdit', false);
    },
    onClickDeleteRule(ruleIndex) {
      this.$set(this.supplierRules[ruleIndex], 'isDelete', !this.supplierRules[ruleIndex].isDelete);
    },
    onClickEditRule(ruleIndex) {
      this.$set(this.supplierRules[ruleIndex], 'newValue', this.supplierRules[ruleIndex].value);
      this.$set(this.supplierRules[ruleIndex], 'isEdit', true);
    },
    onClickCancelRule(ruleIndex) {
      if (this.supplierRules[ruleIndex].value === '') {
        this.supplierRules.splice(ruleIndex, 1);
      } else {
        this.$set(this.supplierRules[ruleIndex], 'newValue', '');
        this.$set(this.supplierRules[ruleIndex], 'isEdit', false);
      }
    },

    async onClickSaveRules() {
      // Save any unsaved rules
      this.supplierRules.forEach((rule, index) => {
        if (rule.isEdit) this.onClickConfirmRule(index);
      });

      await this.save({
        id: this.template._id,
        data: this.supplierRules.filter(r => !r.isDelete)
      });

      this.$emit('updatePreview');

      this.supplierRules = [...this.template.supplierRules].map(p => ({ ...p, show: false, delete: false }));

      this.$toasted.success('Rules saved successfully.');
    }
  }
};
</script>
