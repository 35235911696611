<template>
  <div>
    <div class="block block-rounded">
      <div class="block-content mb-4">
        <h4>Value Classification</h4>
        <p>Pick out final values from within values.</p>
      </div>
    </div>

    <div v-if="currentInvoice && currentInvoice.detailedResults">
      <!-- New Value Form -->
      <div class="mt-4">
        <div class="d-flex justify-content-end align-items-center mb-4">
          <button class="btn btn-primary btn-sm mr-3" @click="onClickShowValueForm"><i class="fa fa-fw fa-plus" /> Add Value</button>
          <button class="btn btn-warning btn-sm" @click="onClickShowRateForm"><i class="fa fa-fw fa-plus" /> Add Rate</button>
        </div>

        <div v-if="valueForm.show" class="block block-rounded">
          <div class="block-content block-content-full mb-4">
            <h5>Add New Value</h5>
            <div v-if="template.bounds.length > 0">
              <FormGroup
                id="valueBound"
                v-model="valueForm.bound"
                label="Bound"
                type="select"
                :options="template.bounds.map(b => ({ value: b.name, label: b.name }))"
                @input="refreshNewValuePreview"
              />
              <label>Bound Preview</label>
              <div v-if="valueForm.bound && valuePreviews.new.boundPreview">
                <table class="table table-borderless table-striped">
                  <tbody>
                    <tr v-for="(line, lineIndex) in valuePreviews.new.boundPreview" :key="lineIndex">
                      <td>{{ line.text }}</td>
                      <td class="text-right align-middle">
                        <span class="badge badge-primary">{{ parseFloat(line.confidence).toFixed(2) }}%</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else class="alert alert-warning mb-0" role="alert">
                <p class="mb-0">No lines within bounds.</p>
              </div>

              <FormGroup id="valueName" v-model="valueForm.name" label="Name" type="text" />

              <FormGroup
                id="valueType"
                v-model="valueForm.type"
                label="Type"
                type="select"
                :options="valueTypes.map(t => ({ value: t, label: t }))"
                @input="refreshNewValuePreview"
              />
              <FormGroup
                id="valueKey"
                v-model="valueForm.key"
                label="Invoice Key"
                type="select"
                :options="invoiceKeys.filter(t => t.types.includes($route.meta.type)).map(t => ({ value: t.key, label: t.friendly }))"
              />
              <div v-if="valueForm.type === 'date'">
                <FormGroup id="valueFormat" v-model="valueForm.format" class="mb-0" type="text" label="Date Format" @input="refreshNewValuePreview" />
                <div class="mb-3">
                  <small><a target="_blank" href="https://momentjs.com/docs/#/displaying/format/">Format syntax</a></small>
                </div>
              </div>
              <FormGroup
                v-if="valueForm.type === 'price'"
                id="valueFormat"
                v-model="valueForm.format"
                type="select"
                label="Price Format"
                :options="priceFormats.map(f => ({ value: f.value, label: f.label }))"
                @input="refreshNewValuePreview"
              />
              <FormGroup
                v-if="valueForm.bound && valuePreviews.new.bound.group === 'seperate'"
                id="valueLineRegex"
                v-model="valueForm.lineRegex"
                type="text"
                label="Line Regex"
                @input="refreshNewValuePreview"
              />
              <div class="form-group">
                <label>Regex</label>
                <div class="input-group">
                  <FormItem id="boundRegex" v-model="valueForm.regex" type="text" @input="refreshNewValuePreview" />
                  <div class="input-group-append">
                    <FormItem
                      id="boundRegexType"
                      v-model="valueForm.regexType"
                      type="select"
                      class="form-control-alt"
                      :options="[
                        { label: 'Capture', value: 'capture' },
                        { label: 'Replace', value: 'replace' }
                      ]"
                      @input="refreshNewValuePreview"
                    />
                  </div>
                </div>
              </div>

              <div v-if="valueForm.bound" class="form-group">
                <label>Preview</label>
                <div>{{ valuePreviews.new.valuePreview }}</div>
              </div>
              <div v-if="valueForm.bound" class="form-group">
                <label>Formatted Preview</label>
                <div>{{ formattedValues.new || 'N/A' }}</div>
              </div>
            </div>
            <div v-else class="alert alert-warning" role="alert">
              <p class="mb-0">No bounds on page.</p>
            </div>

            <!-- <FormGroup id="valueRule" v-model="value.rule" label="Regex" type="text" /> -->
            <button class="btn btn-primary btn-block" :disabled="loading.addValue || template.bounds.length === 0" @click="onClickCreateValue">
              <i class="fa fa-fw fa-plus" /> {{ loading.addValue ? 'Adding...' : 'Add Value' }}
            </button>
          </div>
        </div>

        <!-- RATE FORM -->
        <div v-if="rateForm.show" class="block block-rounded">
          <div class="block-content block-content-full mb-4">
            <h5>Add New Rate</h5>

            <div v-if="template.bounds.length > 0">
              <FormGroup
                id="rateType"
                v-model="rateForm.type"
                label="Type"
                type="select"
                :options="[
                  { label: 'Unit Rate (p/kWh)', value: 'unitRate' },
                  { label: 'Daily (£/day)', value: 'daily' },
                  { label: 'Levy (p/kWh)', value: 'levy' },
                  { label: 'VAT (%)', value: 'tax' },
                  { label: 'Availability (kVA)', value: 'kva' },
                  { label: 'Water (£/m3)', value: 'waterRate' },
                  { label: 'Waste (£/m3)', value: 'wasteRate' }
                ]"
              />

              <FormGroup
                id="rateTypeMultiplier"
                v-model="rateForm.typeMultiplier"
                label="Rate Multiplier"
                type="number"
                min="1"
                max="1000"
                step="1"
              />

              <ul class="nav nav-pills nav-justified push">
                <li class="nav-item mr-1">
                  <a class="nav-link" :class="{ active: rateForm.tab === 'rateName' }" href="#" @click.prevent="onClickNewRateTab('rateName')"
                    >Name</a
                  >
                </li>
                <li class="nav-item mr-1">
                  <a class="nav-link" :class="{ active: rateForm.tab === 'startDate' }" href="#" @click.prevent="onClickNewRateTab('startDate')"
                    >Start Date</a
                  >
                </li>
                <li class="nav-item mr-1">
                  <a class="nav-link" :class="{ active: rateForm.tab === 'endDate' }" href="#" @click.prevent="onClickNewRateTab('endDate')"
                    >End Date</a
                  >
                </li>
                <li class="nav-item mr-1">
                  <a class="nav-link" :class="{ active: rateForm.tab === 'consumption' }" href="#" @click.prevent="onClickNewRateTab('consumption')"
                    >Consumption</a
                  >
                </li>
                <li class="nav-item mr-1">
                  <a class="nav-link" :class="{ active: rateForm.tab === 'unitRate' }" href="#" @click.prevent="onClickNewRateTab('unitRate')"
                    >Unit Rate</a
                  >
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{ active: rateForm.tab === 'cost' }" href="#" @click.prevent="onClickNewRateTab('cost')">Cost</a>
                </li>
                <li v-if="['unitRate', 'waterRate', 'wasteRate'].includes(rateForm.type)" class="nav-item">
                  <a class="nav-link" :class="{ active: rateForm.tab === 'startRead' }" href="#" @click.prevent="onClickNewRateTab('startRead')"
                    >S Read</a
                  >
                </li>
                <li v-if="['unitRate', 'waterRate', 'wasteRate'].includes(rateForm.type)" class="nav-item">
                  <a class="nav-link" :class="{ active: rateForm.tab === 'endRead' }" href="#" @click.prevent="onClickNewRateTab('endRead')"
                    >E Read</a
                  >
                </li>
                <li v-if="['unitRate', 'waterRate', 'wasteRate'].includes(rateForm.type)" class="nav-item">
                  <a
                    class="nav-link"
                    :class="{ active: rateForm.tab === 'startReadType' }"
                    href="#"
                    @click.prevent="onClickNewRateTab('startReadType')"
                    >S Read Type</a
                  >
                </li>
                <li v-if="['unitRate', 'waterRate', 'wasteRate'].includes(rateForm.type)" class="nav-item">
                  <a class="nav-link" :class="{ active: rateForm.tab === 'endReadType' }" href="#" @click.prevent="onClickNewRateTab('endReadType')"
                    >E Read Type</a
                  >
                </li>
              </ul>

              <div v-show="rateForm.tab === 'consumption'">
                <FormGroup
                  id="rateConsumptionBound"
                  v-model="rateForm.consumptionBound"
                  label="Bound"
                  type="select"
                  :options="template.bounds.map(b => ({ value: b.name, label: b.name }))"
                  @input="refreshNewRatePreview"
                />

                <label>Bound Preview</label>
                <div v-if="rateForm.consumptionBound && ratePreviews.new.consumptionBoundPreview">
                  <table class="table table-borderless table-striped">
                    <tbody>
                      <tr v-for="(line, lineIndex) in ratePreviews.new.consumptionBoundPreview" :key="lineIndex">
                        <td>{{ line.text }}</td>
                        <td class="text-right align-middle">
                          <span class="badge badge-primary">{{ parseFloat(line.confidence).toFixed(2) }}%</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-else class="alert alert-warning mb-3" role="alert">
                  <p class="mb-0">No lines within bounds.</p>
                </div>

                <FormGroup
                  v-if="ratePreviews.new.consumptionBound.type === 'bound' && ratePreviews.new.consumptionBound.group === 'seperate'"
                  id="rateLineRegex"
                  v-model="rateForm.consumptionLineRegex"
                  type="text"
                  label="Line Regex"
                  @input="refreshNewRatePreview"
                />

                <div class="form-group">
                  <label>Regex</label>
                  <div class="input-group">
                    <FormItem id="consumptionRegex" v-model="rateForm.consumptionRegex" type="text" @input="refreshNewRatePreview" />
                    <div class="input-group-append">
                      <FormItem
                        id="consumptionRegexType"
                        v-model="rateForm.consumptionRegexType"
                        type="select"
                        class="form-control-alt"
                        :options="[
                          { label: 'Capture', value: 'capture' },
                          { label: 'Replace', value: 'replace' }
                        ]"
                        @input="refreshNewRatePreview"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div v-show="rateForm.tab === 'unitRate'">
                <FormGroup
                  id="rateUnitRateBound"
                  v-model="rateForm.unitRateBound"
                  label="Bound"
                  type="select"
                  :options="template.bounds.map(b => ({ value: b.name, label: b.name }))"
                  @input="refreshNewRatePreview"
                />

                <label>Bound Preview</label>
                <div v-if="rateForm.unitRateBound && ratePreviews.new.unitRateBoundPreview">
                  <table class="table table-borderless table-striped">
                    <tbody>
                      <tr v-for="(line, lineIndex) in ratePreviews.new.unitRateBoundPreview" :key="lineIndex">
                        <td>{{ line.text }}</td>
                        <td class="text-right align-middle">
                          <span class="badge badge-primary">{{ parseFloat(line.confidence).toFixed(2) }}%</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-else class="alert alert-warning mb-3" role="alert">
                  <p class="mb-0">No lines within bounds.</p>
                </div>

                <FormGroup
                  v-if="ratePreviews.new.unitRateBound.type === 'bound' && ratePreviews.new.unitRateBound.group === 'seperate'"
                  id="rateLineRegex"
                  v-model="rateForm.unitRateLineRegex"
                  type="text"
                  label="Line Regex"
                  @input="refreshNewRatePreview"
                />

                <div class="form-group">
                  <label>Regex</label>
                  <div class="input-group">
                    <FormItem id="unitRateRegex" v-model="rateForm.unitRateRegex" type="text" @input="refreshNewRatePreview" />
                    <div class="input-group-append">
                      <FormItem
                        id="unitRateRegexType"
                        v-model="rateForm.unitRateRegexType"
                        type="select"
                        class="form-control-alt"
                        :options="[
                          { label: 'Capture', value: 'capture' },
                          { label: 'Replace', value: 'replace' }
                        ]"
                        @input="refreshNewRatePreview"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="rateForm.tab === 'cost'">
                <FormGroup
                  id="rateCostBound"
                  v-model="rateForm.costBound"
                  label="Bound"
                  type="select"
                  :options="template.bounds.map(b => ({ value: b.name, label: b.name }))"
                  @input="refreshNewRatePreview"
                />

                <label>Bound Preview</label>
                <div v-if="rateForm.costBound && ratePreviews.new.costBoundPreview">
                  <table class="table table-borderless table-striped">
                    <tbody>
                      <tr v-for="(line, lineIndex) in ratePreviews.new.costBoundPreview" :key="lineIndex">
                        <td>{{ line.text }}</td>
                        <td class="text-right align-middle">
                          <span class="badge badge-primary">{{ parseFloat(line.confidence).toFixed(2) }}%</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-else class="alert alert-warning mb-3" role="alert">
                  <p class="mb-0">No lines within bounds.</p>
                </div>
                <FormGroup
                  v-if="ratePreviews.new.costBound.type === 'bound' && ratePreviews.new.costBound.group === 'seperate'"
                  id="rateLineRegex"
                  v-model="rateForm.costLineRegex"
                  type="text"
                  label="Line Regex"
                  @input="refreshNewRatePreview"
                />

                <div class="form-group">
                  <label>Regex</label>
                  <div class="input-group">
                    <FormItem id="costRegex" v-model="rateForm.costRegex" type="text" @input="refreshNewRatePreview" />
                    <div class="input-group-append">
                      <FormItem
                        id="costRegexType"
                        v-model="rateForm.costRegexType"
                        type="select"
                        class="form-control-alt"
                        :options="[
                          { label: 'Capture', value: 'capture' },
                          { label: 'Replace', value: 'replace' }
                        ]"
                        @input="refreshNewRatePreview"
                      />
                    </div>
                  </div>
                </div>

                <FormGroup
                  id="rateCostFormat"
                  v-model="rateForm.costFormat"
                  type="select"
                  label="Cost Price Format"
                  :options="priceFormats.map(f => ({ value: f.value, label: f.label }))"
                  @input="refreshNewRatePreview"
                />
              </div>

              <div v-show="['startRead', 'endRead', 'startReadType', 'endReadType', 'rateName', 'startDate', 'endDate'].includes(rateForm.tab)">
                <FormGroup
                  :id="`rate${capitalize(rateForm.tab)}Bound`"
                  v-model="rateForm[`${rateForm.tab}Bound`]"
                  label="Bound"
                  type="select"
                  :options="template.bounds.map(b => ({ value: b.name, label: b.name }))"
                  @input="refreshNewRatePreview"
                />

                <label>Bound Preview</label>
                <div v-if="rateForm[`${rateForm.tab}Bound`] && ratePreviews.new[`${rateForm.tab}BoundPreview`]">
                  <table class="table table-borderless table-striped">
                    <tbody>
                      <tr v-for="(line, lineIndex) in ratePreviews.new[`${rateForm.tab}BoundPreview`]" :key="lineIndex">
                        <td>{{ line.text }}</td>
                        <td class="text-right align-middle">
                          <span class="badge badge-primary">{{ parseFloat(line.confidence).toFixed(2) }}%</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-else class="alert alert-warning mb-3" role="alert">
                  <p class="mb-0">No lines within bounds.</p>
                </div>

                <FormGroup
                  v-if="ratePreviews.new[`${rateForm.tab}Bound`].type === 'bound' && ratePreviews.new[`${rateForm.tab}Bound`].group === 'seperate'"
                  id="rateLineRegex"
                  v-model="rateForm[`${rateForm.tab}LineRegex`]"
                  type="text"
                  label="Line Regex"
                  @input="refreshNewRatePreview"
                />

                <div class="form-group">
                  <label>Regex</label>
                  <div class="input-group">
                    <FormItem id="${rateForm.tab}Regex" v-model="rateForm[`${rateForm.tab}Regex`]" type="text" @input="refreshNewRatePreview" />
                    <div class="input-group-append">
                      <FormItem
                        id="${rateForm.tab}RegexType"
                        v-model="rateForm[`${rateForm.tab}RegexType`]"
                        type="select"
                        class="form-control-alt"
                        :options="[
                          { label: 'Capture', value: 'capture' },
                          { label: 'Replace', value: 'replace' }
                        ]"
                        @input="refreshNewRatePreview"
                      />
                    </div>
                  </div>
                </div>

                <FormGroup
                  v-if="rateForm.tab === 'startDate'"
                  id="rateCostFormat"
                  v-model="rateForm.startDateFormat"
                  type="text"
                  label="Date Format"
                  @input="refreshNewRatePreview"
                />

                <FormGroup
                  v-if="rateForm.tab === 'endDate'"
                  id="rateCostFormat"
                  v-model="rateForm.endDateFormat"
                  type="text"
                  label="Date Format"
                  @input="refreshNewRatePreview"
                />
              </div>

              <div v-if="rateForm.bound" class="form-group bg-body-light">
                <table class="table table-borderless">
                  <thead>
                    <tr>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Consumption</th>
                      <th>Unit Rate</th>
                      <th>Cost</th>
                      <th v-if="['unitRate', 'waterRate', 'wasteRate'].includes(rateForm.type)">Start Read</th>
                      <th v-if="['unitRate', 'waterRate', 'wasteRate'].includes(rateForm.type)">End Read</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ ratePreviews.new?.rateName }}</td>
                      <td>{{ ratePreviews.new.startDate }} ({{ formattedRateStartDates.new || 'N/A' }})</td>
                      <td>{{ ratePreviews.new.endDate }} ({{ formattedRateEndDates.new || 'N/A' }})</td>
                      <td>{{ ratePreviews.new.consumption }}</td>
                      <td>{{ ratePreviews.new.unitRate }}</td>
                      <td>{{ ratePreviews.new.cost }} ({{ formattedRateCosts.new || 'N/A' }})</td>
                      <td v-if="['unitRate', 'waterRate', 'wasteRate'].includes(rateForm.type)">
                        {{ ratePreviews.new.startRead }} ({{ ratePreviews.new.startReadType }})
                      </td>
                      <td v-if="['unitRate', 'waterRate', 'wasteRate'].includes(rateForm.type)">
                        {{ ratePreviews.new.endRead }} ({{ ratePreviews.new.endReadType }})
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-else class="alert alert-warning" role="alert">
              <p class="mb-0">No bounds on page.</p>
            </div>

            <!-- <FormGroup id="valueRule" v-model="value.rule" label="Regex" type="text" /> -->
            <button class="btn btn-primary btn-block" :disabled="loading.addRate || template.bounds.length === 0" @click="onClickCreateRate">
              <i class="fa fa-fw fa-plus" /> {{ loading.addRate ? 'Adding...' : 'Add Rate' }}
            </button>
          </div>
        </div>
        <!-- RATE FORM END -->
      </div>
      <!-- New Value Form END -->

      <h4>Values</h4>
      <div v-if="values.length > 0">
        <div v-for="value in values" :key="value._id" class="block block-rounded mb-4">
          <div class="block-header" :class="{ 'border-bottom': value.show }">
            <a href="#" @click.prevent="() => onClickEditValue(value._id)"
              ><strong>{{ value.name }}</strong></a
            >
            <span>{{ valuePreviews[value._id].valuePreview || 'N/A' }}</span>
            <span>
              <button class="btn btn-link font-w600 mr-3" @click="() => onClickEditValue(value._id)">Manage</button>
              <button class="btn btn-link font-w600 text-danger" @click="() => onClickDeleteValue(value._id)">Delete</button>
            </span>
          </div>

          <div class="collapse" :class="{ show: value.show }">
            <!-- Edit Value Form -->
            <div class="block-content block-content-full">
              <div v-if="template.bounds.length > 0">
                <FormGroup
                  :id="`valueBound-${value._id}`"
                  v-model="value.bound"
                  label="Bound"
                  type="select"
                  :options="template.bounds.map(b => ({ value: b.name, label: b.name }))"
                  @input="() => refreshValuePreviews(value)"
                />
                <label>Bound Preview</label>

                <div v-if="value.bound && valuePreviews[value._id].boundPreview">
                  <table class="table table-borderless table-striped">
                    <tbody>
                      <tr v-for="(line, lineIndex) in valuePreviews[value._id].boundPreview" :key="lineIndex">
                        <td>{{ line.text }}</td>
                        <td class="text-right align-middle">
                          <span class="badge badge-primary">{{ parseFloat(line.confidence).toFixed(2) }}%</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-else class="alert alert-warning mb-0" role="alert">
                  <p class="mb-0">No lines within bounds.</p>
                </div>

                <FormGroup :id="`valueName-${value._id}`" v-model="value.name" label="Name" type="text" />
                <FormGroup
                  :id="`valueType-${value._id}`"
                  v-model="value.type"
                  label="Type"
                  type="select"
                  :options="valueTypes.map(t => ({ value: t, label: t }))"
                  @input="() => refreshValuePreviews(value)"
                />
                <FormGroup
                  :id="`valueKey-${value._id}`"
                  v-model="value.key"
                  label="Invoice Key"
                  type="select"
                  :options="invoiceKeys.filter(t => t.types.includes($route.meta.type)).map(t => ({ value: t.key, label: t.friendly }))"
                />
                <FormGroup
                  v-if="value.type === 'date'"
                  :id="`valueFormat-${value._id}`"
                  v-model="value.format"
                  type="text"
                  label="Date Format"
                  @input="() => refreshValuePreviews(value)"
                />
                <div v-if="value.type === 'date'">
                  <FormGroup
                    id="valueFormat"
                    v-model="value.format"
                    class="mb-0"
                    type="text"
                    label="Date Format"
                    @input="() => refreshValuePreviews(value)"
                  />
                  <div class="mb-3">
                    <small><a target="_blank" href="https://momentjs.com/docs/#/displaying/format/">Format syntax</a></small>
                  </div>
                </div>
                <FormGroup
                  v-if="value.type === 'price'"
                  :id="`valueFormat-${value._id}`"
                  v-model="value.format"
                  type="select"
                  label="Price Format"
                  :options="priceFormats.map(f => ({ value: f.value, label: f.label }))"
                  @input="() => refreshValuePreviews(value)"
                />
                <FormGroup
                  :id="`valueLineRegex-${value._id}`"
                  v-model="value.lineRegex"
                  type="text"
                  label="Line Regex"
                  @input="() => refreshValuePreviews(value)"
                />
                <div class="form-group">
                  <label>Regex</label>
                  <div class="input-group">
                    <FormItem :id="`valueRegex-${value._id}`" v-model="value.regex" type="text" @input="() => refreshValuePreviews(value)" />
                    <div class="input-group-append">
                      <FormItem
                        :id="`valueRegexType-${value._id}`"
                        v-model="value.regexType"
                        type="select"
                        class="form-control-alt"
                        :options="[
                          { label: 'Capture', value: 'capture' },
                          { label: 'Replace', value: 'replace' }
                        ]"
                        @input="() => refreshValuePreviews(value)"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Preview</label>
                  <div>{{ valuePreviews[value._id].valuePreview || 'N/A' }}</div>
                </div>
                <div class="form-group">
                  <label>Formatted Preview</label>
                  <div>{{ formattedValues[value._id] || 'N/A' }}</div>
                </div>
              </div>
              <div v-else class="alert alert-warning" role="alert">
                <p class="mb-0">Value's bounds not on current page.</p>
              </div>
              <button class="btn btn-primary btn-block mb-4" :disabled="loading.updateValue" @click="() => onClickSaveValue(value)">
                <i class="fa fa-fw fa-save" /> {{ loading.updateValue ? 'Saving...' : 'Save Value' }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="alert alert-warning" role="alert">
        <p class="mb-0">No values found for selected bound.</p>
      </div>

      <h4>Rates</h4>
      <div v-if="rates.length > 0">
        <div v-for="rate in rates" :key="rate._id" class="block block-rounded mb-4">
          <div class="block-header" :class="{ 'border-bottom': rate.show }">
            <div class="d-flex flex-column flex-grow-1">
              <div class="d-flex align-items-center justify-content-between pb-2">
                <a href="#" @click.prevent="() => onClickEditRate(rate._id)"
                  ><strong>{{ ratePreviews[rate._id]?.rateName || 'N/A' }} </strong
                  ><span v-if="ratePreviews[rate._id]?.bound" class="label label-info ml-2">{{ ratePreviews[rate._id].bound.page }}</span></a
                >
                <span>
                  <button class="btn btn-link font-w600 mr-3" @click="() => onClickEditRate(rate._id)">Manage</button>
                  <button class="btn btn-link font-w600 text-danger" @click="() => onClickDeleteRate(rate._id)">Delete</button>
                </span>
              </div>
              <div class="d-flex justify-content-between border-top pt-2">
                <span><strong>S</strong> {{ ratePreviews[rate._id].startDate || 'N/A' }} </span>
                <span><strong>E</strong> {{ ratePreviews[rate._id].endDate || 'N/A' }} </span>
                <span><strong>C</strong> {{ ratePreviews[rate._id].consumption || 'N/A' }} </span>
                <span><strong>U</strong> {{ ratePreviews[rate._id].unitRate || 'N/A' }} </span>
                <span><strong>£</strong> {{ ratePreviews[rate._id].cost || 'N/A' }}</span>
                <span v-if="['unitRate', 'waterRate', 'wasteRate'].includes(rateForm.type)"
                  ><strong>SR</strong> {{ ratePreviews[rate._id].startRead || 'N/A' }} ({{ ratePreviews[rate._id].startReadType || 'N/A' }})</span
                >
                <span v-if="['unitRate', 'waterRate', 'wasteRate'].includes(rateForm.type)"
                  ><strong>ER</strong> {{ ratePreviews[rate._id].endRead || 'N/A' }} ({{ ratePreviews[rate._id].endReadType || 'N/A' }})</span
                >
              </div>
            </div>
          </div>
          <!-- Edit Rate Form -->
          <div class="collapse" :class="{ show: rate.show }">
            <div class="block-content block-content-full">
              <div v-if="template.bounds.length > 0">
                <FormGroup
                  :id="`rateType-${rate._id}`"
                  v-model="rate.type"
                  label="Type"
                  type="select"
                  :options="[
                    { label: 'Unit Rate (p/kWh)', value: 'unitRate' },
                    { label: 'Daily (£/day)', value: 'daily' },
                    { label: 'Levy (p/kWh)', value: 'levy' },
                    { label: 'VAT (%)', value: 'tax' },
                    { label: 'Availability (kVA)', value: 'kva' },
                    { label: 'Water (£/m3)', value: 'waterRate' },
                    { label: 'Waste (£/m3)', value: 'wasteRate' }
                  ]"
                />

                <FormGroup
                  :id="`rateTypeMultiplier-${rate._id}`"
                  v-model="rate.typeMultiplier"
                  label="Rate Multiplier"
                  type="number"
                  min="1"
                  max="1000"
                  step="1"
                />

                <ul class="nav nav-pills nav-justified push">
                  <li class="nav-item mr-1">
                    <a class="nav-link" :class="{ active: rate.tab === 'rateName' }" href="#" @click.prevent="onClickRateTab(rate._id, 'rateName')"
                      >Name</a
                    >
                  </li>
                  <li class="nav-item mr-1">
                    <a class="nav-link" :class="{ active: rate.tab === 'startDate' }" href="#" @click.prevent="onClickRateTab(rate._id, 'startDate')"
                      >Start Date</a
                    >
                  </li>
                  <li class="nav-item mr-1">
                    <a class="nav-link" :class="{ active: rate.tab === 'endDate' }" href="#" @click.prevent="onClickRateTab(rate._id, 'endDate')"
                      >End Date</a
                    >
                  </li>
                  <li class="nav-item mr-1">
                    <a
                      class="nav-link"
                      :class="{ active: rate.tab === 'consumption' }"
                      href="#"
                      @click.prevent="onClickRateTab(rate._id, 'consumption')"
                      >Consumption</a
                    >
                  </li>
                  <li class="nav-item mr-1">
                    <a class="nav-link" :class="{ active: rate.tab === 'unitRate' }" href="#" @click.prevent="onClickRateTab(rate._id, 'unitRate')"
                      >Unit Rate</a
                    >
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" :class="{ active: rate.tab === 'cost' }" href="#" @click.prevent="onClickRateTab(rate._id, 'cost')">Cost</a>
                  </li>
                  <li v-if="['unitRate', 'waterRate', 'wasteRate'].includes(rate.type)" class="nav-item">
                    <a class="nav-link" :class="{ active: rate.tab === 'startRead' }" href="#" @click.prevent="onClickRateTab(rate._id, 'startRead')"
                      >S Read</a
                    >
                  </li>
                  <li v-if="['unitRate', 'waterRate', 'wasteRate'].includes(rate.type)" class="nav-item">
                    <a class="nav-link" :class="{ active: rate.tab === 'endRead' }" href="#" @click.prevent="onClickRateTab(rate._id, 'endRead')"
                      >E Read</a
                    >
                  </li>
                  <li v-if="['unitRate', 'waterRate', 'wasteRate'].includes(rate.type)" class="nav-item">
                    <a
                      class="nav-link"
                      :class="{ active: rate.tab === 'startReadType' }"
                      href="#"
                      @click.prevent="onClickRateTab(rate._id, 'startReadType')"
                      >S Read Type</a
                    >
                  </li>
                  <li v-if="['unitRate', 'waterRate', 'wasteRate'].includes(rate.type)" class="nav-item">
                    <a
                      class="nav-link"
                      :class="{ active: rate.tab === 'endReadType' }"
                      href="#"
                      @click.prevent="onClickRateTab(rate._id, 'endReadType')"
                      >E Read Type</a
                    >
                  </li>
                </ul>

                <div v-show="rate.tab === 'consumption'">
                  <FormGroup
                    :id="`rateConsumptionBound-${rate._id}`"
                    v-model="rate.consumptionBound"
                    label="Bound"
                    type="select"
                    :options="template.bounds.map(b => ({ value: b.name, label: b.name }))"
                    @input="refreshRatePreviews(rate)"
                  />

                  <label>Bound Preview</label>
                  <div v-if="rate.consumptionBound && ratePreviews[rate._id].consumptionBoundPreview">
                    <table class="table table-borderless table-striped">
                      <tbody>
                        <tr v-for="(line, lineIndex) in ratePreviews[rate._id].consumptionBoundPreview" :key="lineIndex">
                          <td>{{ line.text }}</td>
                          <td class="text-right align-middle">
                            <span class="badge badge-primary">{{ parseFloat(line.confidence).toFixed(2) }}%</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-else class="alert alert-warning mb-3" role="alert">
                    <p class="mb-0">No lines within bounds.</p>
                  </div>

                  <FormGroup
                    v-if="ratePreviews[rate._id].consumptionBound.type === 'bound' && ratePreviews[rate._id].consumptionBound.group === 'seperate'"
                    :id="`rateConsumptionLineRegex-${rate._id}`"
                    v-model="rate.consumptionLineRegex"
                    type="text"
                    label="Line Regex"
                    @input="refreshRatePreviews(rate)"
                  />

                  <div class="form-group">
                    <label>Regex</label>
                    <div class="input-group">
                      <FormItem
                        :id="`rateConsumptionRegex-${rate._id}`"
                        v-model="rate.consumptionRegex"
                        type="text"
                        @input="refreshRatePreviews(rate)"
                      />
                      <div class="input-group-append">
                        <FormItem
                          :id="`rateConsumptionRegexType-${rate._id}`"
                          v-model="rate.consumptionRegexType"
                          type="select"
                          class="form-control-alt"
                          :options="[
                            { label: 'Capture', value: 'capture' },
                            { label: 'Replace', value: 'replace' }
                          ]"
                          @input="refreshRatePreviews(rate)"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div v-show="rate.tab === 'unitRate'">
                  <FormGroup
                    :id="`rateUnitRateBound-${rate._id}`"
                    v-model="rate.unitRateBound"
                    label="Bound"
                    type="select"
                    :options="template.bounds.map(b => ({ value: b.name, label: b.name }))"
                    @input="refreshRatePreviews(rate)"
                  />

                  <label>Bound Preview</label>
                  <div v-if="rate.unitRateBound && ratePreviews[rate._id].unitRateBoundPreview">
                    <table class="table table-borderless table-striped">
                      <tbody>
                        <tr v-for="(line, lineIndex) in ratePreviews[rate._id].unitRateBoundPreview" :key="lineIndex">
                          <td>{{ line.text }}</td>
                          <td class="text-right align-middle">
                            <span class="badge badge-primary">{{ parseFloat(line.confidence).toFixed(2) }}%</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-else class="alert alert-warning mb-3" role="alert">
                    <p class="mb-0">No lines within bounds.</p>
                  </div>

                  <FormGroup
                    v-if="ratePreviews[rate._id].unitRateBound.type === 'bound' && ratePreviews[rate._id].unitRateBound.group === 'seperate'"
                    :id="`rateUnitRateLineRegex-${rate._id}`"
                    v-model="rate.unitRateLineRegex"
                    type="text"
                    label="Line Regex"
                    @input="refreshRatePreviews(rate)"
                  />

                  <div class="form-group">
                    <label>Regex</label>
                    <div class="input-group">
                      <FormItem :id="`rateUnitRateRegex-${rate._id}`" v-model="rate.unitRateRegex" type="text" @input="refreshRatePreviews(rate)" />
                      <div class="input-group-append">
                        <FormItem
                          :id="`rateUnitRateRegexType-${rate._id}`"
                          v-model="rate.unitRateRegexType"
                          type="select"
                          class="form-control-alt"
                          :options="[
                            { label: 'Capture', value: 'capture' },
                            { label: 'Replace', value: 'replace' }
                          ]"
                          @input="refreshRatePreviews(rate)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div v-show="rate.tab === 'cost'">
                  <FormGroup
                    :id="`rateCostBound-${rate._id}`"
                    v-model="rate.costBound"
                    label="Bound"
                    type="select"
                    :options="template.bounds.map(b => ({ value: b.name, label: b.name }))"
                    @input="refreshRatePreviews(rate)"
                  />

                  <label>Bound Preview</label>
                  <div v-if="rate.costBound && ratePreviews[rate._id].costBoundPreview">
                    <table class="table table-borderless table-striped">
                      <tbody>
                        <tr v-for="(line, lineIndex) in ratePreviews[rate._id].costBoundPreview" :key="lineIndex">
                          <td>{{ line.text }}</td>
                          <td class="text-right align-middle">
                            <span class="badge badge-primary">{{ parseFloat(line.confidence).toFixed(2) }}%</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-else class="alert alert-warning mb-3" role="alert">
                    <p class="mb-0">No lines within bounds.</p>
                  </div>
                  <FormGroup
                    v-if="ratePreviews[rate._id].costBound.type === 'bound' && ratePreviews[rate._id].costBound.group === 'seperate'"
                    :id="`rateCostLineRegex-${rate._id}`"
                    v-model="rate.costLineRegex"
                    type="text"
                    label="Line Regex"
                    @input="refreshRatePreviews(rate)"
                  />

                  <div class="form-group">
                    <label>Regex</label>
                    <div class="input-group">
                      <FormItem :id="`rateCostRegex-${rate._id}`" v-model="rate.costRegex" type="text" @input="refreshRatePreviews(rate)" />
                      <div class="input-group-append">
                        <FormItem
                          :id="`rateCostRegexType-${rate._id}`"
                          v-model="rate.costRegexType"
                          type="select"
                          class="form-control-alt"
                          :options="[
                            { label: 'Capture', value: 'capture' },
                            { label: 'Replace', value: 'replace' }
                          ]"
                          @input="refreshRatePreviews(rate)"
                        />
                      </div>
                    </div>
                  </div>

                  <FormGroup
                    :id="`rateCostFormat-${rate._id}`"
                    v-model="rate.costFormat"
                    type="select"
                    label="Cost Price Format"
                    :options="priceFormats.map(f => ({ value: f.value, label: f.label }))"
                    @input="refreshRatePreviews(rate)"
                  />
                </div>

                <div v-show="['startRead', 'endRead', 'startReadType', 'endReadType', 'rateName', 'startDate', 'endDate'].includes(rate.tab)">
                  <FormGroup
                    :id="`rate${capitalize(rate.tab)}Bound-${rate._id}`"
                    v-model="rate[`${rate.tab}Bound`]"
                    label="Bound"
                    type="select"
                    :options="template.bounds.map(b => ({ value: b.name, label: b.name }))"
                    @input="refreshRatePreviews(rate)"
                  />

                  <label>Bound Preview</label>
                  <div v-if="rate[`${rate.tab}Bound`] && ratePreviews[rate._id][`${rate.tab}BoundPreview`]">
                    <table class="table table-borderless table-striped">
                      <tbody>
                        <tr v-for="(line, lineIndex) in ratePreviews[rate._id][`${rate.tab}BoundPreview`]" :key="lineIndex">
                          <td>{{ line.text }}</td>
                          <td class="text-right align-middle">
                            <span class="badge badge-primary">{{ parseFloat(line.confidence).toFixed(2) }}%</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-else class="alert alert-warning mb-3" role="alert">
                    <p class="mb-0">No lines within bounds.</p>
                  </div>

                  <FormGroup
                    v-if="
                      ratePreviews[rate._id][`${rate.tab}Bound`].type === 'bound' && ratePreviews[rate._id][`${rate.tab}Bound`].group === 'seperate'
                    "
                    :id="`rateStartReadLineRegex-${rate._id}`"
                    v-model="rate[`${rate.tab}LineRegex`]"
                    type="text"
                    label="Line Regex"
                    @input="refreshRatePreviews(rate)"
                  />

                  <div class="form-group">
                    <label>Regex</label>
                    <div class="input-group">
                      <FormItem
                        :id="`rateStartReadRegex-${rate._id}`"
                        v-model="rate[`${rate.tab}Regex`]"
                        type="text"
                        @input="refreshRatePreviews(rate)"
                      />
                      <div class="input-group-append">
                        <FormItem
                          :id="`rateStartReadRegexType-${rate._id}`"
                          v-model="rate[`${rate.tab}RegexType`]"
                          type="select"
                          class="form-control-alt"
                          :options="[
                            { label: 'Capture', value: 'capture' },
                            { label: 'Replace', value: 'replace' }
                          ]"
                          @input="refreshRatePreviews(rate)"
                        />
                      </div>
                    </div>
                  </div>

                  <FormGroup
                    v-if="rate.tab === 'startDate'"
                    :id="`rateStartDateFormat-${rate._id}`"
                    v-model="rate.startDateFormat"
                    type="text"
                    label="Date Format"
                    @input="refreshRatePreviews(rate)"
                  />

                  <FormGroup
                    v-if="rate.tab === 'endDate'"
                    :id="`rateEndDateFormat-${rate._id}`"
                    v-model="rate.endDateFormat"
                    type="text"
                    label="Date Format"
                    @input="refreshRatePreviews(rate)"
                  />
                </div>

                <div class="form-group bg-body-light">
                  <table class="table table-borderless">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Consumption</th>
                        <th>Unit Rate</th>
                        <th>Cost</th>
                        <th v-if="['unitRate', 'waterRate', 'wasteRate'].includes(rate.type)">Sart Read</th>
                        <th v-if="['unitRate', 'waterRate', 'wasteRate'].includes(rate.type)">End Read</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ ratePreviews[rate._id]?.rateName }}</td>
                        <td>{{ ratePreviews[rate._id]?.startDate }}<br />({{ formattedRateStartDates[rate._id] || 'N/A' }})</td>
                        <td>{{ ratePreviews[rate._id].endDate }}<br />({{ formattedRateEndDates[rate._id] || 'N/A' }})</td>
                        <td>{{ ratePreviews[rate._id].consumption }}</td>
                        <td>{{ ratePreviews[rate._id].unitRate }}</td>
                        <td>{{ ratePreviews[rate._id].cost }} ({{ formattedRateCosts[rate._id] || 'N/A' }})</td>
                        <td v-if="['unitRate', 'waterRate', 'wasteRate'].includes(rate.type)">
                          {{ ratePreviews[rate._id].startRead }} ({{ ratePreviews[rate._id].startReadType }})
                        </td>
                        <td v-if="['unitRate', 'waterRate', 'wasteRate'].includes(rate.type)">
                          {{ ratePreviews[rate._id].endRead }} ({{ ratePreviews[rate._id].endReadType }})
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-else class="alert alert-warning" role="alert">
                <p class="mb-0">Rate's bounds not on current page.</p>
              </div>
              <button class="btn btn-primary btn-block mb-4" :disabled="loading.updateRate" @click="() => onClickSaveRate(rate)">
                <i class="fa fa-fw fa-save" /> {{ loading.updateRate ? 'Saving...' : 'Save Rate' }}
              </button>
            </div>
          </div>
          <!-- Edit Rate Form END -->
        </div>
      </div>
      <div v-else class="alert alert-warning" role="alert">
        <p class="mb-0">No rates found for selected bound.</p>
      </div>
    </div>
    <div v-else class="alert alert-warning" role="alert">
      <p class="mb-0">No invoice loaded.</p>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import { getBoundPreview, getValuePreview, getRatePreview, getLinesWithinPageByName, getCellsWithinPageByName } from '../../../lib/templateHelpers';
import { capitalize } from '../../../lib/helpers';

import FormGroup from '@/components/FormGroup';
import FormItem from '@/components/FormItem';

export default {
  name: 'SupplierTemplateValues',
  components: {
    FormGroup,
    FormItem
  },
  props: {
    currentLines: {
      type: Array,
      required: true
    },
    currentCells: {
      type: Array,
      required: true
    },
    currentInvoice: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      valueEditForm: {},
      rateEditForm: {},
      valueForm: {
        name: '',
        key: '',
        type: 'string',
        format: '',
        lineRegex: '',
        regex: '',
        regexType: 'capture',
        show: false,
        bound: ''
      },
      rateForm: {
        name: '',
        type: 'unitRate',
        typeMultiplier: 1,
        consumptionBound: '',
        consumptionRegex: '',
        consumptionRegexType: 'capture',
        consumptionLineRegex: '',
        unitRateBound: '',
        unitRateRegex: '',
        unitRateRegexType: 'capture',
        unitRateLineRegex: '',
        costFormat: '',
        costBound: '',
        costRegex: '',
        costRegexType: 'capture',
        costLineRegex: '',
        startReadBound: '',
        startReadRegex: '',
        startReadRegexType: 'capture',
        startReadLineRegex: '',
        endReadBound: '',
        endReadRegex: '',
        endReadRegexType: 'capture',
        endReadLineRegex: '',
        startReadTypeBound: '',
        startReadTypeRegex: '',
        startReadTypeRegexType: 'capture',
        startReadTypeLineRegex: '',
        endReadTypeBound: '',
        endReadTypeRegex: '',
        endReadTypeRegexType: 'capture',
        endReadTypeLineRegex: '',
        startDateBound: '',
        startDateRegex: '',
        startDateRegexType: 'capture',
        startDateLineRegex: '',
        startDateFormat: '',
        endDateBound: '',
        endDateRegex: '',
        endDateRegexType: 'capture',
        endDateLineRegex: '',
        endDateFormat: '',
        rateNameBound: '',
        rateNameRegex: '',
        rateNameRegexType: 'capture',
        rateNameLineRegex: '',
        tab: 'consumption',
        show: false
      },
      valueTypes: ['string', 'number', 'date', 'price'],
      values: [],
      rates: [],
      formattedValues: {},
      formattedRateCosts: {},
      formattedRateStartDates: {},
      formattedRateEndDates: {},
      valuePreviews: {
        new: { valuePreview: 'N/A', boundPreview: [], bound: {} }
      },
      ratePreviews: {
        new: {
          consumption: 'N/A',
          consumptionBound: {},
          consumptionBoundPreview: [],
          unitRate: 'N/A',
          unitRateBound: {},
          unitRateBoundPreview: [],
          cost: 'N/A',
          costBound: {},
          costBoundPreview: [],
          endRead: 'N/A',
          endReadBound: {},
          endReadBoundPreview: [],
          endReadType: 'N/A',
          endReadTypeBound: {},
          endReadTypeBoundPreview: [],
          startRead: 'N/A',
          startReadBound: {},
          startReadBoundPreview: [],
          startReadType: 'N/A',
          startReadTypeBound: {},
          startReadTypeBoundPreview: [],
          rateName: 'N/A',
          rateNameBoundPreview: [],
          rateNameBound: {},
          startDate: 'N/A',
          startDateBoundPreview: [],
          startDateBound: {},
          endDate: 'N/A',
          endDateBoundPreview: [],
          endDateBound: {}
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      supplier: 'supplier/supplier',
      template: 'invoiceTemplate/template',
      loading: 'invoiceTemplate/loading',
      priceFormats: 'invoiceTemplate/priceFormats',
      invoiceKeys: 'invoiceTemplate/invoiceKeys',
      preview: 'invoiceTemplate/preview'
    })
  },
  watch: {
    currentInvoice(newInvoice) {
      if (!newInvoice.detailedResults) return;
      console.log('Change current invoice');

      this.values = [...this.template.values].map(v => ({ ...v, show: false }));
      this.rates = [...this.template.rates].map(v => ({ ...v, show: false, tab: 'consumption' }));

      this.refreshNewValuePreview();
      this.refreshNewRatePreview();
      this.refreshValuePreviews();
      this.refreshRatePreviews();
    },
    'preview.currentPage'() {
      console.log('Change current page');

      this.values = [...this.template.values].map(v => ({ ...v, show: false }));
      this.rates = [...this.template.rates].map(v => ({ ...v, show: false, tab: 'consumption' }));

      this.refreshNewValuePreview();
      this.refreshNewRatePreview();
      this.refreshValuePreviews();
      this.refreshRatePreviews();
    },
    template() {
      console.log('Change current template');

      this.values = [...this.template.values].map(v => ({ ...v, show: false }));
      this.rates = [...this.template.rates].map(v => ({ ...v, show: false, tab: 'consumption' }));

      this.refreshNewValuePreview();
      this.refreshNewRatePreview();
      this.refreshValuePreviews();
      this.refreshRatePreviews();
    }
  },
  mounted() {
    this.values = [...this.template.values].map(v => ({ ...v, show: false }));
    this.rates = [...this.template.rates].map(v => ({ ...v, show: false, tab: 'consumption' }));

    this.refreshNewValuePreview();
    this.refreshNewRatePreview();
    this.refreshValuePreviews();
    this.refreshRatePreviews();
  },
  methods: {
    ...mapActions({
      addValue: 'invoiceTemplate/addValue',
      updateValue: 'invoiceTemplate/updateValue',
      deleteValue: 'invoiceTemplate/deleteValue',
      addRate: 'invoiceTemplate/addRate',
      updateRate: 'invoiceTemplate/updateRate',
      deleteRate: 'invoiceTemplate/deleteRate',
      formatValues: 'invoiceTemplate/formatValues'
    }),
    ...mapMutations({
      setSelectedValues: 'invoiceTemplate/SET_SELECTED_VALUES',
      setSelectedRates: 'invoiceTemplate/SET_SELECTED_RATES'
    }),
    capitalize,
    refreshNewValuePreview() {
      if (!this.valueForm.show || !this.valueForm.bound) return { valuePreview: 'N/A', boundPreview: [], bound: {} };

      let bound = this.template.bounds.find(bound => bound.name === this.valueForm.bound);

      if (!bound) {
        bound = this.template.bounds[0];
      }

      if (!bound) {
        this.$set(this.valuePreviews, 'new', { valuePreview: 'N/A', boundPreview: [], bound: {} });
        return;
      }
      this.$set(this.valueForm, 'boundType', bound.type);

      const { lines: boundPreview } = getBoundPreview(bound.type === 'table' ? this.currentCells : this.currentLines, bound);
      const valuePreview = getValuePreview(boundPreview, this.valueForm);

      this.$set(this.valuePreviews, 'new', { valuePreview, boundPreview, bound });
      this.refreshFormattedValues();
    },
    refreshValuePreviews(value) {
      if (!this.currentInvoice.detailedResults) return;

      let values = value ? [value] : this.values;

      values.forEach(value => {
        const bound = this.template.bounds.find(bound => bound.name === value.bound);

        if (!bound) {
          this.$set(this.valuePreviews, value._id, { valuePreview: 'N/A', boundPreview: [], bound: {} });
          return;
        }

        const linesWithinPageByName = getLinesWithinPageByName(
          this.template,
          this.currentInvoice.detailedResults,
          bound.page,
          this.preview.currentPage
        );

        const cellsWithinPageByName = getCellsWithinPageByName(
          this.template,
          this.currentInvoice.detailedResults,
          bound.page,
          this.preview.currentPage
        );

        const { lines: boundPreview } = getBoundPreview(bound.type === 'table' ? cellsWithinPageByName : linesWithinPageByName, bound);
        const valuePreview = getValuePreview(boundPreview, value);

        this.$set(this.valuePreviews, value._id, { valuePreview, boundPreview, bound });
      });

      this.refreshFormattedValues();
    },
    refreshNewRatePreview() {
      this.refreshRatePreview('new', this.rateForm);
      this.refreshFormattedRates();
    },
    refreshRatePreviews(rate) {
      if (!this.currentInvoice.detailedResults) return;

      let rates = rate ? [rate] : this.rates;

      rates.forEach(r => {
        this.refreshRatePreview(r._id, r);
      });

      this.refreshFormattedRates();
    },
    refreshRatePreview(key, existingRate) {
      const preview = {
        consumptionBoundPreview: [],
        unitRateBoundPreview: [],
        costBoundPreview: [],
        startReadBoundPreview: [],
        endReadBoundPreview: [],
        startReadBoundTypePreview: [],
        endReadBoundTypePreview: [],
        rateNamePreview: [],
        startDatePreview: [],
        endDatePreview: [],
        consumptionBound: {},
        unitRateBound: {},
        costBound: {},
        startReadBound: {},
        endReadBound: {},
        startReadTypeBound: {},
        rateNameBound: {},
        startDateBound: {},
        endDateBound: {},
        endReadTypeBound: {},
        consumption: 'N/A',
        unitRate: 'N/A',
        cost: 'N/A',
        startRead: 'N/A',
        endRead: 'N/A',
        startReadType: 'N/A',
        endReadType: 'N/A',
        rateName: 'N/A',
        startDate: 'N/A',
        endDate: 'N/A'
      };

      const rate = existingRate || this.rateForm;

      if (rate.consumptionBound) {
        let consumptionBound = this.template.bounds.find(b => b.name === rate.consumptionBound);

        if (!consumptionBound) {
          consumptionBound = this.template.bounds[0];
        }

        if (consumptionBound) {
          const linesWithinPageByName = getLinesWithinPageByName(
            this.template,
            this.currentInvoice.detailedResults,
            consumptionBound.page,
            this.preview.currentPage
          );
          const cellsWithinPageByName = getCellsWithinPageByName(
            this.template,
            this.currentInvoice.detailedResults,
            consumptionBound.page,
            this.preview.currentPage
          );

          const { lines: consumptionBoundPreview } = getBoundPreview(
            consumptionBound.type === 'table' ? cellsWithinPageByName : linesWithinPageByName,
            consumptionBound
          );
          preview.consumptionBoundPreview = consumptionBoundPreview;
          preview.consumptionBound = consumptionBound;

          const consumptionPreview = getRatePreview(consumptionBoundPreview, 'consumption', rate);
          preview.consumption = consumptionPreview;
        }
      }

      if (rate.unitRateBound) {
        let unitRateBound = this.template.bounds.find(b => b.name === rate.unitRateBound);

        if (!unitRateBound) {
          unitRateBound = this.template.bounds[0];
        }

        if (unitRateBound) {
          const linesWithinPageByName = getLinesWithinPageByName(
            this.template,
            this.currentInvoice.detailedResults,
            unitRateBound.page,
            this.preview.currentPage
          );
          const cellsWithinPageByName = getCellsWithinPageByName(
            this.template,
            this.currentInvoice.detailedResults,
            unitRateBound.page,
            this.preview.currentPage
          );

          const { lines: unitRateBoundPreview } = getBoundPreview(
            unitRateBound.type === 'table' ? cellsWithinPageByName : linesWithinPageByName,
            unitRateBound
          );
          preview.unitRateBoundPreview = unitRateBoundPreview;
          preview.unitRateBound = unitRateBound;

          const unitRatePreview = getRatePreview(unitRateBoundPreview, 'unitRate', rate);
          preview.unitRate = unitRatePreview;
        }
      }

      if (rate.costBound) {
        let costBound = this.template.bounds.find(b => b.name === rate.costBound);

        if (!costBound) {
          costBound = this.template.bounds[0];
        }

        if (costBound) {
          const linesWithinPageByName = getLinesWithinPageByName(
            this.template,
            this.currentInvoice.detailedResults,
            costBound.page,
            this.preview.currentPage
          );
          const cellsWithinPageByName = getCellsWithinPageByName(
            this.template,
            this.currentInvoice.detailedResults,
            costBound.page,
            this.preview.currentPage
          );

          const { lines: costBoundPreview } = getBoundPreview(costBound.type === 'table' ? cellsWithinPageByName : linesWithinPageByName, costBound);
          preview.costBoundPreview = costBoundPreview;
          preview.costBound = costBound;

          const costPreview = getRatePreview(costBoundPreview, 'cost', rate);
          preview.cost = costPreview;
        }
      }

      if (rate.startReadBound) {
        let startReadBound = this.template.bounds.find(b => b.name === rate.startReadBound);

        if (!startReadBound) {
          startReadBound = this.template.bounds[0];
        }

        if (startReadBound) {
          const linesWithinPageByName = getLinesWithinPageByName(
            this.template,
            this.currentInvoice.detailedResults,
            startReadBound.page,
            this.preview.currentPage
          );
          const cellsWithinPageByName = getCellsWithinPageByName(
            this.template,
            this.currentInvoice.detailedResults,
            startReadBound.page,
            this.preview.currentPage
          );

          const { lines: startReadBoundPreview } = getBoundPreview(
            startReadBound.type === 'table' ? cellsWithinPageByName : linesWithinPageByName,
            startReadBound
          );
          preview.startReadBoundPreview = startReadBoundPreview;
          preview.startReadBound = startReadBound;

          const startReadPreview = getRatePreview(startReadBoundPreview, 'startRead', rate);
          preview.startRead = startReadPreview;
        }
      }

      if (rate.endReadBound) {
        let endReadBound = this.template.bounds.find(b => b.name === rate.endReadBound);

        if (!endReadBound) {
          endReadBound = this.template.bounds[0];
        }

        if (endReadBound) {
          const linesWithinPageByName = getLinesWithinPageByName(
            this.template,
            this.currentInvoice.detailedResults,
            endReadBound.page,
            this.preview.currentPage
          );
          const cellsWithinPageByName = getCellsWithinPageByName(
            this.template,
            this.currentInvoice.detailedResults,
            endReadBound.page,
            this.preview.currentPage
          );

          const { lines: endReadBoundPreview } = getBoundPreview(
            endReadBound.type === 'table' ? cellsWithinPageByName : linesWithinPageByName,
            endReadBound
          );
          preview.endReadBoundPreview = endReadBoundPreview;
          preview.endReadBound = endReadBound;

          const endReadPreview = getRatePreview(endReadBoundPreview, 'endRead', rate);
          preview.endRead = endReadPreview;
        }
      }

      if (rate.startReadTypeBound) {
        let startReadTypeBound = this.template.bounds.find(b => b.name === rate.startReadTypeBound);

        if (!startReadTypeBound) {
          startReadTypeBound = this.template.bounds[0];
        }

        if (startReadTypeBound) {
          const linesWithinPageByName = getLinesWithinPageByName(
            this.template,
            this.currentInvoice.detailedResults,
            startReadTypeBound.page,
            this.preview.currentPage
          );
          const cellsWithinPageByName = getCellsWithinPageByName(
            this.template,
            this.currentInvoice.detailedResults,
            startReadTypeBound.page,
            this.preview.currentPage
          );

          const { lines: startReadTypeBoundPreview } = getBoundPreview(
            startReadTypeBound.type === 'table' ? cellsWithinPageByName : linesWithinPageByName,
            startReadTypeBound
          );
          preview.startReadTypeBoundPreview = startReadTypeBoundPreview;
          preview.startReadTypeBound = startReadTypeBound;

          const startReadTypePreview = getRatePreview(startReadTypeBoundPreview, 'startReadType', rate);
          preview.startReadType = startReadTypePreview;
        }
      }

      if (rate.endReadTypeBound) {
        let endReadTypeBound = this.template.bounds.find(b => b.name === rate.endReadTypeBound);

        if (!endReadTypeBound) {
          endReadTypeBound = this.template.bounds[0];
        }

        if (endReadTypeBound) {
          const linesWithinPageByName = getLinesWithinPageByName(
            this.template,
            this.currentInvoice.detailedResults,
            endReadTypeBound.page,
            this.preview.currentPage
          );
          const cellsWithinPageByName = getCellsWithinPageByName(
            this.template,
            this.currentInvoice.detailedResults,
            endReadTypeBound.page,
            this.preview.currentPage
          );

          const { lines: endReadTypeBoundPreview } = getBoundPreview(
            endReadTypeBound.type === 'table' ? cellsWithinPageByName : linesWithinPageByName,
            endReadTypeBound
          );
          preview.endReadTypeBoundPreview = endReadTypeBoundPreview;
          preview.endReadTypeBound = endReadTypeBound;

          const endReadTypePreview = getRatePreview(endReadTypeBoundPreview, 'endReadType', rate);
          preview.endReadType = endReadTypePreview;
        }
      }

      if (rate.rateNameBound) {
        let rateNameBound = this.template.bounds.find(b => b.name === rate.rateNameBound);

        if (!rateNameBound) {
          rateNameBound = this.template.bounds[0];
        }

        if (rateNameBound) {
          const linesWithinPageByName = getLinesWithinPageByName(
            this.template,
            this.currentInvoice.detailedResults,
            rateNameBound.page,
            this.preview.currentPage
          );
          const cellsWithinPageByName = getCellsWithinPageByName(
            this.template,
            this.currentInvoice.detailedResults,
            rateNameBound.page,
            this.preview.currentPage
          );

          const { lines: rateNameBoundPreview } = getBoundPreview(
            rateNameBound.type === 'table' ? cellsWithinPageByName : linesWithinPageByName,
            rateNameBound
          );
          preview.rateNameBoundPreview = rateNameBoundPreview;
          preview.rateNameBound = rateNameBound;

          const rateNamePreview = getRatePreview(rateNameBoundPreview, 'rateName', rate);
          preview.rateName = rateNamePreview;
        }
      }

      if (rate.startDateBound) {
        let startDateBound = this.template.bounds.find(b => b.name === rate.startDateBound);

        if (!startDateBound) {
          startDateBound = this.template.bounds[0];
        }

        if (startDateBound) {
          const linesWithinPageByName = getLinesWithinPageByName(
            this.template,
            this.currentInvoice.detailedResults,
            startDateBound.page,
            this.preview.currentPage
          );
          const cellsWithinPageByName = getCellsWithinPageByName(
            this.template,
            this.currentInvoice.detailedResults,
            startDateBound.page,
            this.preview.currentPage
          );

          const { lines: startDateBoundPreview } = getBoundPreview(
            startDateBound.type === 'table' ? cellsWithinPageByName : linesWithinPageByName,
            startDateBound
          );
          preview.startDateBoundPreview = startDateBoundPreview;
          preview.startDateBound = startDateBound;

          const startDatePreview = getRatePreview(startDateBoundPreview, 'startDate', rate);
          preview.startDate = startDatePreview;
        }
      }

      if (rate.endDateBound) {
        let endDateBound = this.template.bounds.find(b => b.name === rate.endDateBound);

        if (!endDateBound) {
          endDateBound = this.template.bounds[0];
        }

        if (endDateBound) {
          const linesWithinPageByName = getLinesWithinPageByName(
            this.template,
            this.currentInvoice.detailedResults,
            endDateBound.page,
            this.preview.currentPage
          );
          const cellsWithinPageByName = getCellsWithinPageByName(
            this.template,
            this.currentInvoice.detailedResults,
            endDateBound.page,
            this.preview.currentPage
          );

          const { lines: endDateBoundPreview } = getBoundPreview(
            endDateBound.type === 'table' ? cellsWithinPageByName : linesWithinPageByName,
            endDateBound
          );
          preview.endDateBoundPreview = endDateBoundPreview;
          preview.endDateBound = endDateBound;

          const endDatePreview = getRatePreview(endDateBoundPreview, 'endDate', rate);
          preview.endDate = endDatePreview;
        }
      }
      this.$set(this.ratePreviews, key, preview);
    },
    async refreshFormattedValues() {
      const formatRequest = {
        values: [],
        types: [],
        formats: [],
        ids: []
      };

      if (Object.keys(this.valuePreviews).length === 0) return;

      Object.keys(this.valuePreviews).forEach(valueId => {
        const preview = this.valuePreviews[valueId].valuePreview;
        const value = valueId === 'new' ? this.valueForm : this.values.find(value => value._id === valueId);

        if (value && preview && preview !== 'N/A') {
          formatRequest.values.push(preview);
          formatRequest.types.push(value.type);
          formatRequest.formats.push(value.format);
          formatRequest.ids.push(valueId);
        }
      });

      console.log('preview', formatRequest);

      const results = await this.formatValues({
        values: formatRequest.values.join('|'),
        types: formatRequest.types.join('|'),
        formats: formatRequest.formats.join('|')
      });

      formatRequest.ids.forEach((valueId, i) => {
        this.$set(this.formattedValues, valueId, results[i]);
      });
    },
    async refreshFormattedRates() {
      const formatRequest = {
        values: [],
        types: [],
        formats: [],
        ids: []
      };
      if (Object.keys(this.ratePreviews).length === 0) return;
      Object.keys(this.ratePreviews).forEach(rateId => {
        const costPreview = this.ratePreviews[rateId].cost;
        const startDatePreview = this.ratePreviews[rateId].startDate;
        const endDatePreview = this.ratePreviews[rateId].endDate;
        const rate = rateId === 'new' ? this.rateForm : this.rates.find(rate => rate._id === rateId);

        if (rate && costPreview && costPreview !== 'N/A') {
          formatRequest.values.push(costPreview);
          formatRequest.types.push('price');
          formatRequest.formats.push(rate.costFormat);
          formatRequest.ids.push({ id: rateId, type: 'cost' });
        }

        if (rate && startDatePreview && startDatePreview !== 'N/A') {
          formatRequest.values.push(startDatePreview);
          formatRequest.types.push('date');
          formatRequest.formats.push(rate.startDateFormat);
          formatRequest.ids.push({ id: rateId, type: 'startDate' });
        }

        if (rate && endDatePreview && endDatePreview !== 'N/A') {
          formatRequest.values.push(endDatePreview);
          formatRequest.types.push('date');
          formatRequest.formats.push(rate.endDateFormat);
          formatRequest.ids.push({ id: rateId, type: 'endDate' });
        }
      });

      const results = await this.formatValues({
        values: formatRequest.values.join('|'),
        types: formatRequest.types.join('|'),
        formats: formatRequest.formats.join('|')
      });

      formatRequest.ids.forEach((rateId, i) => {
        if (rateId.type === 'cost') this.$set(this.formattedRateCosts, rateId.id, results[i]);
        if (rateId.type === 'startDate') this.$set(this.formattedRateStartDates, rateId.id, results[i]);
        if (rateId.type === 'endDate') this.$set(this.formattedRateEndDates, rateId.id, results[i]);
      });
    },

    resetValueForm(overrides = {}) {
      this.valueForm = {
        name: '',
        key: '',
        type: 'string',
        format: '',
        regex: '',
        regexType: 'capture',
        show: false,
        ...overrides
      };

      this.refreshNewValuePreview();
    },
    async onClickCreateValue() {
      await this.addValue({
        id: this.template._id,
        data: {
          name: this.valueForm.name,
          key: this.valueForm.key,
          type: this.valueForm.type,
          format: this.valueForm.format,
          lineRegex: this.valueForm.lineRegex,
          regex: this.valueForm.regex,
          regexType: this.valueForm.regexType,
          bound: this.valueForm.bound
        }
      });

      this.values = [...this.template.values].map(v => ({ ...v, show: false }));

      this.resetValueForm();
      this.refreshValuePreviews();

      this.$toasted.success('Value created successfully.');
    },
    async onClickSaveValue(value) {
      await this.updateValue({
        id: this.template._id,
        valueId: value._id,
        data: {
          name: value.name,
          key: value.key,
          type: value.type,
          format: value.format,
          lineRegex: value.lineRegex,
          regex: value.regex,
          regexType: value.regexType,
          bound: value.bound
        }
      });

      this.$toasted.success('Value saved successfully.');

      this.values = [...this.template.values].map(v => ({ ...v, show: false }));
    },
    onClickEditValue(id) {
      const index = this.values.findIndex(v => id === v._id);
      const original = this.template.values.find(value => value._id === id);

      // Set back to original
      if (this.values[index].show) {
        this.$set(this.values, index, { ...original, show: false });
      } else {
        this.$set(this.values, index, { ...original, show: true });
      }
    },
    async onClickDeleteValue(id) {
      await this.deleteValue({
        id: this.template._id,
        valueId: id
      });

      this.values = [...this.template.values].map(v => ({ ...v, show: false }));

      this.$toasted.success('Value deleted successfully.');
    },
    onClickShowValueForm() {
      this.resetValueForm({ show: !this.valueForm.show });

      if (!this.valueForm.bound) {
        this.valueForm.bound = this.template.bounds[0].name;
      }

      this.refreshNewValuePreview();
    },
    onClickShowRateForm() {
      this.refreshNewRatePreview();
      this.resetRateForm({ show: !this.rateForm.show });

      if (!this.rateForm.bound) {
        this.rateForm.bound = this.template.bounds[0].name;
      }
    },
    onClickNewRateTab(tab) {
      this.rateForm.tab = tab;
    },
    onClickRateTab(id, tab) {
      const index = this.rates.findIndex(v => id === v._id);
      this.$set(this.rates[index], 'tab', tab);
    },

    // Rates,
    resetRateForm(overrides = {}) {
      this.rateForm = {
        name: '',
        type: 'unitRate',
        typeMultiplier: 1,
        consumptionRegex: '',
        consumptionRegexType: 'capture',
        consumptionLineRegex: '',
        unitRateRegex: '',
        unitRateRegexType: 'capture',
        unitRateLineRegex: '',
        costFormat: '',
        costRegex: '',
        costRegexType: 'capture',
        costLineRegex: '',
        startReadRegex: '',
        startReadRegexType: 'capture',
        startReadLineRegex: '',
        endReadRegex: '',
        endReadRegexType: 'capture',
        endReadLineRegex: '',
        startReadTypeRegex: '',
        startReadTypeRegexType: 'capture',
        startReadTypeLineRegex: '',
        endReadTypeRegex: '',
        endReadTypeRegexType: 'capture',
        endReadTypeLineRegex: '',
        rateNameRegex: '',
        rateNameRegexType: 'capture',
        rateNameLineRegex: '',
        startDateRegex: '',
        startDateFormat: '',
        startDateRegexType: 'capture',
        startDateLineRegex: '',
        endDateRegex: '',
        endDateFormat: '',
        endDateRegexType: 'capture',
        endDateLineRegex: '',
        tab: 'consumption',
        show: false,
        ...overrides
      };

      this.$set(this.ratePreviews, 'new', {
        consumptionBoundPreview: [],
        unitRateBoundPreview: [],
        costBoundPreview: [],
        startReadBoundPreview: [],
        endReadBoundPreview: [],
        startReadTypeBoundPreview: [],
        endReadTypeBoundPreview: [],
        rateNameBoundPreview: [],
        startDateBoundPreview: [],
        endDateBoundPreview: [],
        consumptionBound: {},
        unitRateBound: {},
        costBound: {},
        startReadBound: {},
        endReadBound: {},
        startReadTypeBound: {},
        endReadTypeBound: {},
        rateNameBound: {},
        startDateBound: {},
        endDateBound: {},
        consumption: 'N/A',
        unitRate: 'N/A',
        cost: 'N/A',
        startRead: 'N/A',
        endRead: 'N/A',
        startReadType: 'N/A',
        endReadType: 'N/A',
        rateName: 'N/A',
        startDate: 'N/A',
        endDate: 'N/A'
      });
    },
    onClickEditRate(id) {
      const index = this.rates.findIndex(v => id === v._id);
      const original = this.template.rates.find(rate => rate._id === id);

      // Set back to original
      if (this.rates[index].show) {
        this.$set(this.rates, index, { ...original, show: false, tab: 'consumption' });
      } else {
        this.$set(this.rates, index, { ...original, show: true, tab: 'consumption' });
      }
    },
    async onClickDeleteRate(id) {
      await this.deleteRate({
        id: this.template._id,
        rateId: id
      });

      this.rates = [...this.template.rates].map(v => ({ ...v, show: false, tab: 'consumption' }));

      this.$toasted.success('Rate deleted successfully.');
    },
    async onClickCreateRate() {
      await this.addRate({
        id: this.template._id,
        data: {
          type: this.rateForm.type,
          typeMultiplier: this.rateForm.typeMultiplier,
          consumptionBound: this.rateForm.consumptionBound,
          consumptionRegex: this.rateForm.consumptionRegex,
          consumptionRegexType: this.rateForm.consumptionRegexType,
          consumptionLineRegex: this.rateForm.consumptionLineRegex,
          unitRateBound: this.rateForm.unitRateBound,
          unitRateRegex: this.rateForm.unitRateRegex,
          unitRateRegexType: this.rateForm.unitRateRegexType,
          unitRateLineRegex: this.rateForm.unitRateLineRegex,
          costBound: this.rateForm.costBound,
          costRegex: this.rateForm.costRegex,
          costRegexType: this.rateForm.costRegexType,
          costLineRegex: this.rateForm.costLineRegex,
          costFormat: this.rateForm.costFormat,
          startReadBound: this.rateForm.startReadBound,
          startReadRegex: this.rateForm.startReadRegex,
          startReadRegexType: this.rateForm.startReadRegexType,
          startReadLineRegex: this.rateForm.startReadLineRegex,
          endReadBound: this.rateForm.endReadBound,
          endReadRegex: this.rateForm.endReadRegex,
          endReadRegexType: this.rateForm.endReadRegexType,
          endReadLineRegex: this.rateForm.endReadLineRegex,
          startReadTypeBound: this.rateForm.startReadTypeBound,
          startReadTypeRegex: this.rateForm.startReadTypeRegex,
          startReadTypeRegexType: this.rateForm.startReadTypeRegexType,
          startReadTypeLineRegex: this.rateForm.startReadTypeLineRegex,
          endReadTypeBound: this.rateForm.endReadTypeBound,
          endReadTypeRegex: this.rateForm.endReadTypeRegex,
          endReadTypeRegexType: this.rateForm.endReadTypeRegexType,
          endReadTypeLineRegex: this.rateForm.endReadTypeLineRegex,
          rateNameBound: this.rateForm.rateNameBound,
          rateNameRegex: this.rateForm.rateNameRegex,
          rateNameRegexType: this.rateForm.rateNameRegexType,
          rateNameLineRegex: this.rateForm.rateNameLineRegex,
          startDateBound: this.rateForm.startDateBound,
          startDateRegex: this.rateForm.startDateRegex,
          startDateRegexType: this.rateForm.startDateRegexType,
          startDateLineRegex: this.rateForm.startDateLineRegex,
          startDateFormat: this.rateForm.startDateFormat,
          endDateBound: this.rateForm.endDateBound,
          endDateRegex: this.rateForm.endDateRegex,
          endDateRegexType: this.rateForm.endDateRegexType,
          endDateLineRegex: this.rateForm.endDateLineRegex,
          endDateFormat: this.rateForm.endDateFormat
        }
      });

      this.rates = [...this.template.rates].map(v => ({ ...v, show: false, tab: 'consumption' }));

      this.resetRateForm({ show: false });
      this.refreshRatePreviews();

      this.$toasted.success('Rate created successfully.');
    },
    async onClickSaveRate(rate) {
      await this.updateRate({
        id: this.template._id,
        rateId: rate._id,
        data: {
          type: rate.type,
          typeMultiplier: rate.typeMultiplier,
          consumptionBound: rate.consumptionBound,
          consumptionRegex: rate.consumptionRegex,
          consumptionRegexType: rate.consumptionRegexType,
          consumptionLineRegex: rate.consumptionLineRegex,
          unitRateBound: rate.unitRateBound,
          unitRateRegex: rate.unitRateRegex,
          unitRateRegexType: rate.unitRateRegexType,
          unitRateLineRegex: rate.unitRateLineRegex,
          costBound: rate.costBound,
          costRegex: rate.costRegex,
          costRegexType: rate.costRegexType,
          costLineRegex: rate.costLineRegex,
          costFormat: rate.costFormat,
          startReadBound: rate.startReadBound,
          startReadRegex: rate.startReadRegex,
          startReadRegexType: rate.startReadRegexType,
          startReadLineRegex: rate.startReadLineRegex,
          endReadBound: rate.endReadBound,
          endReadRegex: rate.endReadRegex,
          endReadRegexType: rate.endReadRegexType,
          endReadLineRegex: rate.endReadLineRegex,
          startReadTypeBound: rate.startReadTypeBound,
          startReadTypeRegex: rate.startReadTypeRegex,
          startReadTypeRegexType: rate.startReadTypeRegexType,
          startReadTypeLineRegex: rate.startReadTypeLineRegex,
          endReadTypeBound: rate.endReadTypeBound,
          endReadTypeRegex: rate.endReadTypeRegex,
          endReadTypeRegexType: rate.endReadTypeRegexType,
          endReadTypeLineRegex: rate.endReadTypeLineRegex,
          rateNameBound: rate.rateNameBound,
          rateNameRegex: rate.rateNameRegex,
          rateNameRegexType: rate.rateNameRegexType,
          rateNameLineRegex: rate.rateNameLineRegex,
          startDateBound: rate.startDateBound,
          startDateRegex: rate.startDateRegex,
          startDateRegexType: rate.startDateRegexType,
          startDateLineRegex: rate.startDateLineRegex,
          startDateFormat: rate.startDateFormat,
          endDateBound: rate.endDateBound,
          endDateRegex: rate.endDateRegex,
          endDateRegexType: rate.endDateRegexType,
          endDateLineRegex: rate.endDateLineRegex,
          endDateFormat: rate.endDateFormat
        }
      });

      this.rates = [...this.template.rates].map(v => ({ ...v, show: false, tab: 'consumption' }));
      this.refreshRatePreviews();

      this.$toasted.success('Rates saved successfully.');
    }
  }
};
</script>
