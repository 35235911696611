<template>
  <div>
    <div class="block block-rounded">
      <div class="block-content mb-4">
        <h4>Version Classification</h4>
        <p>
          When any of the rules below match on any page, the invoice will be classed as <strong>{{ versionName }}</strong
          >.
        </p>
      </div>
    </div>

    <FormGroup id="version" v-model="versionName" label="Version Name" type="text" />

    <FormGroup
      id="version"
      v-model="versionMatchType"
      label="Match Type"
      type="radio"
      :options="[
        { label: 'Any', value: 'any' },
        { label: 'All', value: 'all' }
      ]"
    />

    <div class="d-flex justify-content-between align-items-center mb-4">
      <h5 class="mb-0">Rules</h5>
      <button class="btn btn-primary btn-sm" @click="() => onClickAddRule(index)"><i class="fa fa-fw fa-plus" /> Add Rule</button>
    </div>

    <div v-if="versionRules.length > 0">
      <div v-for="(rule, index) in versionRules" :key="index" class="block block-rounded mb-4">
        <div class="block-content block-content-full">
          <div v-if="rule.isEdit" class="input-group">
            <FormItem :id="`rule${index}-value`" v-model="rule.newValue" placeholder="Enter a regex rule..." :is-alt="true" type="text" />
            <div class="input-group-append">
              <button class="btn btn-primary" @click="() => onClickConfirmRule(index)">
                <i class="fa fa-fw fa-check" />
              </button>
              <button class="btn btn-danger" @click="() => onClickCancelRule(index)">
                <i class="fa fa-fw fa-times" />
              </button>
            </div>
          </div>
          <div v-else class="d-flex justify-content-between align-items-center">
            <strong :class="{ 'text-danger': rule.isDelete, 'text-strike': rule.isDelete }">{{ rule.value }}</strong>
            <span>
              <button v-if="!rule.isDelete" class="btn btn-link font-w600 mr-3" @click="() => onClickEditRule(index)">Manage</button>
              <button v-if="!rule.isDelete" class="btn btn-link font-w600 text-danger" @click="() => onClickDeleteRule(index)">Delete</button>
              <button v-if="rule.isDelete" class="btn btn-link font-w600" @click="() => onClickDeleteRule(index)">Undo</button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="alert alert-warning" role="alert">
      <p class="mb-0">No rules set.</p>
    </div>

    <button class="btn btn-primary btn-block mb-4" :disabled="loading.saveVersionRules" @click="onClickSave">
      <i class="fa fa-fw fa-save" /> {{ loading.saveVersionRules ? 'Saving...' : 'Save Changes' }}
    </button>

    <button class="btn btn-danger btn-block" :disabled="loading.deleteTemplate" @click="onClickDelete">
      <i class="fa fa-fw fa-exclamation-triangle" /> {{ loading.deleteTemplate ? 'Deleting...' : 'Delete Template' }}
    </button>

    <ConfirmModal
      :open="!!isDeleteModal"
      title="Confirm delete"
      :text="`You are about to delete ${template.version} of this template. All supplier rules, page rules, bounds and
    values will be deleted for this version.`"
      @submit="onConfirmDelete"
      @close="
        () => {
          isDeleteModal = false;
        }
      "
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import FormItem from '@/components/FormItem';
import FormGroup from '@/components/FormGroup';
import ConfirmModal from '@/components/ConfirmModal';

export default {
  name: 'SupplierTemplateVersions',
  components: {
    ConfirmModal,
    FormItem,
    FormGroup
  },
  data() {
    return {
      isDeleteModal: false,
      versionRules: [],
      versionName: '',
      versionMatchType: 'any'
    };
  },
  watch: {
    template() {
      this.versionName = this.template.version;
      this.versionRules = [...this.template.versionRules].map(r => ({ ...r, isEdit: false, isDelete: false }));
      this.versionMatchType = this.template.versionMatchType;
    }
  },
  mounted() {
    this.versionName = this.template.version;

    if (this.template.versionRules && this.template.versionRules.length > 0) {
      this.versionRules = [...this.template.versionRules].map(r => ({ ...r, isEdit: false, isDelete: false }));
    }

    this.versionMatchType = this.template.versionMatchType;
  },
  computed: {
    ...mapGetters({
      template: 'invoiceTemplate/template',
      loading: 'invoiceTemplate/loading',
      supplier: 'supplier/supplier'
    })
  },
  methods: {
    ...mapActions({
      saveRules: 'invoiceTemplate/saveVersionRules',
      updateTemplate: 'invoiceTemplate/updateTemplate',
      deleteTemplate: 'invoiceTemplate/deleteTemplate'
    }),

    onClickAddRule() {
      this.versionRules.push({
        type: 'regex',
        value: '',
        newValue: '',
        isEdit: true
      });
    },
    onClickConfirmRule(ruleIndex) {
      this.$set(this.versionRules[ruleIndex], 'value', this.versionRules[ruleIndex].newValue);
      this.$set(this.versionRules[ruleIndex], 'newValue', '');
      this.$set(this.versionRules[ruleIndex], 'isEdit', false);
    },
    onClickDeleteRule(ruleIndex) {
      this.$set(this.versionRules[ruleIndex], 'isDelete', !this.versionRules[ruleIndex].isDelete);
    },
    onClickEditRule(ruleIndex) {
      this.$set(this.versionRules[ruleIndex], 'newValue', this.versionRules[ruleIndex].value);
      this.$set(this.versionRules[ruleIndex], 'isEdit', true);
    },
    onClickCancelRule(ruleIndex) {
      if (this.versionRules[ruleIndex].value === '') {
        this.versionRules.splice(ruleIndex, 1);
      } else {
        this.$set(this.versionRules[ruleIndex], 'newValue', '');
        this.$set(this.versionRules[ruleIndex], 'isEdit', false);
      }
    },

    async onClickSave() {
      // Save any unsaved rules
      this.versionRules.forEach((rule, index) => {
        if (rule.isEdit) this.onClickConfirmRule(index);
      });

      await this.saveRules({
        id: this.template._id,
        data: this.versionRules.filter(r => !r.isDelete)
      });

      const templateChanges = {};

      if (this.versionName !== this.template.version) {
        templateChanges.version = this.versionName;
      }

      if (this.versionMatchType !== this.template.versionMatchType) {
        templateChanges.versionMatchType = this.versionMatchType;
      }

      if (Object.keys(templateChanges).length > 0) {
        await this.updateTemplate({
          id: this.template._id,
          data: templateChanges
        });
      }

      this.$emit('updatePreview');

      this.versionName = this.template.version;
      this.versionRules = [...this.template.versionRules].map(p => ({ ...p, show: false, delete: false }));
      this.versionMatchType = this.template.versionMatchType;

      this.$toasted.success('Rules saved successfully.');
    },

    onClickDelete() {
      this.isDeleteModal = true;
    },
    async onConfirmDelete() {
      await this.deleteTemplate({
        id: this.template._id
      });

      this.$toasted.success('Template deleted successfully.');
    }
  }
};
</script>
